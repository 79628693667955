import { Audits } from './audits'
import { Summaries } from './summaries'

const MainSummaries = () => {
    return (
        <div className="main-summaries">
             <Summaries />
             <Audits />
        </div>
    )
}

export default MainSummaries
