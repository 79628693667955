import React from "react"
import ApiPath from '../../../admin/assets/App/api-path'
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../../admin/assets/App/Badges"
import AcceptRejectIncomingReference from "./accept-reject-incoming-reference"
import ApproveDisapproveIncomingReference from "./approve-disapprove-incoming-reference"
import ReassignIncomingReference from "./reassign-incoming-reference"

class IncomingReferencesTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            data: [],
            displayViewDialog: false,
            displayApproveDialog: false,
            displayAcceptDialog: false,
            displayReassignDialog: false,
            itemSlug: "",
        }

        this.ViewItem = this.ViewItem.bind(this)
        this.AcceptItem = this.AcceptItem.bind(this)
        this.ApproveItem = this.ApproveItem.bind(this)
        this.ReassignReference = this.ReassignReference.bind(this)

    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                data: this.props.item,
                itemSlug: this.props.item.slug,
            })
        }
    }

    ViewItem() {
        //Display the dialog
        const slug = this.props.item.slug;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
            },
        }

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(res => res.json())
            .then(res => {
                if (res && res.ok) {
                    this.props.setViewData(res.body.data);
                    this.props.setDisplayView(true);
                }
            })

    }

    ReassignReference() {
        /**
         * Display the re-assign reference
         * dialog
         */
        this.setState({
            displayReassignDialog: true,
        })
    }

    AcceptItem() {
        /**
         * Accept an item by
         * showing a dialog...
         * t
         */

        this.setState({
            displayAcceptDialog: true,
        })
    }

    ApproveItem() {

        /**
         * Approving an item
         * will entail
         * selecting
         * the proper item
         * and then
         * validating the
         * action, to avoid
         * accidental deletes...
         */

        this.setState({
            displayApproveDialog: true,
            itemSlug: this.props.item.slug,
        })

    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const hideButtons = this.props.item?.actionStatus === "cancelled" ? "align-center d-none" : "align-center"

        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = (new Date(this.props.item.dueDate).getTime() < new Date().getTime()) ? "Yes" : "No";

        const displayAccept = this.props.item?.actionStatus === "pending" ? "btn-grey mr-1" : "btn-grey d-none";
        const displayApproval = (this.props.item?.actionStatus === "accepted") ? "btn-green mr-1" : "btn-green mr-1 d-none"

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 13)}...</td>
                    <td>{this.props.item?.customerAccountNumber}</td>
                    <td>{this.props.item?.customerAccountName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td className={hideButtons} width="301">
                        <button type="button"
                            id="btnView"
                            className="btn-grey mr-1"
                            onClick={() => this.ViewItem()}>Details
                        </button>
                        <button
                            className={displayAccept}
                            type="button"
                            id="btnAcceptReject"
                            onClick={() => this.AcceptItem()}>
                            Accept/Reject
                        </button>
                        <button
                            type="button"
                            id="btnApproveDisapprove"
                            className={displayApproval}
                            onClick={() => this.ApproveItem()}>
                            Approve/Disapprove
                        </button>

                    </td>
                    <td className="align-center">
                        <button type="button"
                            id="btnReassign"
                            className="btn-grey"
                            onClick={() => this.ReassignReference()}
                        ><i className="bx bx-transfer-alt"></i> Reassign</button>
                    </td>

                </tr>

                {
                    this.state.displayAcceptDialog &&
                    <AcceptRejectIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayAcceptDialog={this.state.displayAcceptDialog}
                        setDisplayAcceptDialog={value => this.setState({ displayAcceptDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setAcceptSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }

                {
                    this.state.displayApproveDialog &&
                    <ApproveDisapproveIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayApproveDialog={this.state.displayApproveDialog}
                        setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setApproveSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
                {
                    this.state.displayReassignDialog &&
                    <ReassignIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayReassignDialog={this.state.displayReassignDialog}
                        setDisplayReassignDialog={value => this.setState({ displayReassignDialog: value })}
                        data={this.props.item}
                        referenceNo={this.props.item.referenceNo}
                        institution={this.props.item?.receiverInstitution?.slug}
                        setReassignSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
            </>
        );
    }
}

export default IncomingReferencesTableRow
