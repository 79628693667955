import React from 'react'
import ApiPath from '../../admin/assets/App/api-path';

class BillingFeesForm extends React.Component {
    constructor() {
        super();
        this.state = {
            txtBillingAmount: "",
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.headersData = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {

        this?.props.setIsLoaderVisible(true);
        const options = {
            method: "GET",
            headers: this?.headersData,
        };

        fetch(`${ApiPath}api/admin/vat/latest-vat`, options)
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                return response.json();
            })
            ?.then((response) => {
                if (response) {
                    if (response.ok) {
                        //Success
                        this.setState({ txtBillingAmount: response.body.data.value });
                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                    }
                }
            })
            ?.catch((error) => {
                this?.props.setIsLoaderVisible(false);
                this?.props.ShowToast(error.messsage, "exclamation");
            });

    }

    handleClick() {
        try {
            if (parseInt(this.state.txtBillingAmount) <= 0 || this.state.txtBillingAmount === "") {
                this.props.ShowToast(
                    "You must enter a numeric value more than zero (0) to proceed",
                    "exclamation"
                );
                document
                    .getElementById("txtBillingAmount")
                    .classList.add("error-border");
                document.getElementById("txtBillingAmount").focus();
                return;
            }

            //Else, good to go
            this.props.setIsLoaderVisible(true);

            const data = { value: this.state.txtBillingAmount };
            const options = {
                method: "POST",
                headers: this?.headersData,
                body: JSON.stringify(data),
            };

            fetch(ApiPath + "api/admin/vat", options)
                .then((response) => {
                    this.props.setIsLoaderVisible(false);
                    return response.json();
                })
                .then((response) => {
                    this.props.setIsLoaderVisible(false);
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast(
                                "Billing Fees updated successfully!",
                                "success"
                            );
                        } else {
                            this.props.ShowToast(response.message, "exclamation");
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value", "exclamation");
                    }
                })
                .catch((error) => {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(error.message, "exclamation");
                });
        } catch (err) {
            this.props.setIsLoaderVisible(false);
            this.props.ShowToast(err.toString(), "exclamation");
        }
    }

    handleChange(e) {
        this.setState({ txtBillingAmount: e?.target.value });
    }

    render() {
        return (
            <>
                <div className="title mb-3">
                    <h3>Billing Fees</h3>
                </div>
                <div className="form-row-4 mb-2">
                    <div className="input-group">
                        <label>Enter Billing Fees</label>
                        <input
                            type="number"
                            id="txtBillingAmount"
                            name="txtBillingAmount"
                            className="form-control"
                            onChange={e => this.handleChange(e)}
                            value={this.state.txtBillingAmount}
                        />
                        <label className="control-info">
                            <i className="icofont-warning-alt"></i> Billing Fees Amount
                        </label>
                    </div>
                    <div className="input-group">
                        <button
                            type="button"
                            id="btnBillingFees"
                            className="btn-green t-tip special-button"
                            data-title="Update Billing Fees"
                            onClick={this.handleClick}
                        ><i className="icofont-upload-alt"></i> Update
                        </button>
                    </div>
                    <div className="input-group">
                        <button
                            type="button"
                            id="refresh"
                            className="btn-grey t-tip d-none"
                            data-title="Refresh"
                            onClick={this.getData}
                        >
                            <i className="icofont-refresh"></i>
                            Billing Fees
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default BillingFeesForm
