import React from "react";
import ApiPath from "../assets/App/api-path";
import objectToCSV from "../assets/App/object-to-csv";
import ExportToCSVFile from "../assets/App/ExportToCSVFile";
import SplitDate from "../assets/App/split-date";
import PageChanger from "../assets/App/PageChanger";

export class AuditLogsForm extends React.Component {
    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.ExportPage = this.ExportPage.bind(this)
        this.RefreshForm = this.RefreshForm.bind(this)
        this.handleItemsCount = this.handleItemsCount.bind(this)

    }

    componentDidMount() {
        this.getData()
    }

    RefreshForm() {
        //Get data again
        this.getData()
    }

    ExportPage() {
        //Move the data to a specified csvFile
        const thisData = this.state.apiData.map(item => {
            return ({
                "time": item.createdAt,
                "date": item.causer,
                "user": JSON.parse(item.properties).email,
                "email": JSON.parse(item.properties).action,
                "description": item.description,
                "userIP": JSON.parse(item.properties).ip,
                "macAdress": "-",
            })
        })
        const csvData = objectToCSV(thisData)
        ExportToCSVFile(csvData, "audit-logs.csv")
    }

    getData() {

        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            }
        }

        fetch(`${ApiPath}api/admin/audit-logs?page=${this.state.currentSelectedPage}&limit=${this.state.perPage}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.auditLogs.data,
                            metaData: response.body.auditLogs.meta,
                        }, () => {

                            this.setState({
                                perPage: this.state.metaData?.per_page,
                                lastPage: this.state.metaData?.last_page,
                                currentSelectedPage: this.state.metaData?.current_page,
                            }, () => {
                                //Set this array for the looping
                                const arrPages = [];
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrPages.push({ "id": i, "name": "Item " + i });
                                }
                                this.setState({
                                    arrayPages: arrPages,
                                })
                            })

                        })
                    } else {
                        this.props.ShowToast("Unable to load table. If this persists, please contact your admin.", "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    handleItemsCount(e) {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getData()
        })
    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => this.getData())
    }

    render() {
        let counter = 0;
        const TableRows = this.state.apiData && this.state.apiData.map(item => {
            counter++;
            //This next item ensures that a proper counter is established
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter
            const properties = (JSON.parse(item.properties))
            return (
                <tr key={item.id}>
                    <td className="align-center">{itemCounter}.</td>
                    <td>{SplitDate(item.createdAt).dateTime}</td>
                    <td>{item.causer}</td>
                    <td>{properties.email}</td>
                    <td>{properties.action}</td>
                    <td>{item.description}</td>
                    <td>{properties.ip}</td>
                    <td className="align-center">-</td>
                </tr>
            )
        })


        const navButtons = this.state.arrayPages && this.state.arrayPages.map(item => {
            const active = this.state.currentSelectedPage === item.id ? "active" : "";

            return (
                <button
                    key={item.id}
                    onClick={() => this.handleSelectedPage(item.id)}
                    className={active}>{item.id}
                </button>
            )
        })

        return (
            <div className="branch-summaries">
                <div className="summaries">
                    <div className="title">
                        <h3>Audit Logs</h3>
                        <div className="buttons">
                            <button id="btnRefresh" type="button" className="btn-grey t-tip mr-1" data-title="Refresh" onClick={this.RefreshForm}>
                                <i className="icofont-refresh"></i> Refresh
                            </button>
                            <button id="btnExport" type="button" className="btn-green t-tip mr-1" data-title="Export Audit Logs" onClick={this.ExportPage}>
                                <i className="icofont-airplane-alt"></i> Export
                            </button>
                            <button id="testButton" className="d-none" onClick={e => this.handleItemsCount(e)}></button>
                        </div>
                    </div>
                    <div className="table-container">
                        <table className="bordered-table">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-center">#</th>
                                    <th scope="col" className="align-left">Time</th>
                                    <th scope="col" className="align-left">User</th>
                                    <th scope="col" className="align-left">Email</th>
                                    <th scope="col" className="align-left">Activity Type</th>
                                    <th scope="col" className="align-left">Details</th>
                                    <th scope="col" className="align-left">User IP Address</th>
                                    <th scope="col" className="align-center">User Mac Address</th>
                                </tr>
                            </thead>
                            <tbody id="tbl_audit_logs">
                                {TableRows}
                            </tbody>
                        </table>
                    </div>
                    <PageChanger
                        handleItemsCount={this.handleItemsCount}
                        perPage={this.state.pagePage}
                    />
                    <div className="pagination">
                        <button className="pag" onClick={() => this.handleSelectedPage(1)}><i className="bx bx-chevrons-left"></i></button>
                        {navButtons}
                        <button className="pag1" onClick={() => this.handleSelectedPage(this.state.lastPage)}><i className="bx bx-chevrons-right"></i></button>
                    </div>

                </div>
            </div>
        )
    }
}
