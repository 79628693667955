import Base64ToBlob from "./base64toBlob";

const CreateFile = (dataToCreate) => {

    var ImageURL = dataToCreate || "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/" +
        "2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj / 2wBDAQcHBwo" +
        "IChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj / wgARCAGQAlgDASIAAhEBAxEB / " +
        "8QAHAAAAgMBAQEBAAAAAAAAAAAAAQIAAwQFBgcI / 8QAGQEBAQEBAQEAAA"

    // Split the base64 string in data and contentType
    var block = ImageURL.split("base64");
    // Get the content type of the image
    var contentType = block[0].split("data")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    return Base64ToBlob(realData, contentType);

}

export default CreateFile
