const SearchList = (props) => {
    return (
        <label className="search-list"> {props.itemName}
            <input
                type="radio"
                id={props.id}
                name="search"
                value={props.value}
                onChange={props.ChangeSearchCriteria}
                checked={props.searchField === props.id}
            />
            <span></span>
        </label>
    );
}

export default SearchList;
