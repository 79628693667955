import ApiPath from "../../admin/assets/App/api-path"
import objectToCSV from "../../admin/assets/App/object-to-csv"
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile"
import BranchManagementTableRows from "./branch-management-table-rows"
import CreateBranchDialog from "./create-branch-dialog"
import EditBranchDialog from "./edit-branch-dialog"
import React from "react";
import { Enums } from "../../_common/enums"
import PageChanger from "../../admin/assets/App/PageChanger"
import SearchList from "../../admin/assets/App/SearchList"
import BranchSearchList from "../../admin/assets/data/BranchSearchList"

class BranchManagementForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            searchGroupState: false,
            searchTitle: "Branch Name",
            searchField: "branchName",
            searchValue: "",
            rowData: [],
            editClicked: false,
            institutions: [],
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }

        this.CreateBranch = this.CreateBranch.bind(this)
        this.handleExportBranches = this.handleExportBranches.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleItemsCount = this.handleItemsCount.bind(this)

    }

    handleChange(e) {
        this.setState({ searchValue: e?.target.value })
    }

    handleItemsCount(e) {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getBranches()
        })
    }

    EditBranch = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID);

        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    getBranches() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: Enums.headersData(sessionStorage.getItem("session_token"))
        }

        const searchValue = (this.state.searchField === "institution" && this.state.searchValue !== "")
            ? this.state.institutions.find(item => item.name === this.state.searchValue)?.slug
            : this.state.searchValue;

        fetch(`${ApiPath}api/branch/fetch-branches?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${searchValue}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.branches.data,
                            metaData: response.body.branches.meta,
                            fetchSuccessful: true,
                            arrayPages: [],

                        }, () => {
                            const arrayPages = [];
                            this.setState({
                                lastPage: this.state.metaData?.last_page,
                                perPage: this.state.metaData?.per_page,
                                currentSelectedPage: this.state.metaData?.current_page,
                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrayPages.push({ "id": i, "name": "Item " + i, "other": "description" });
                                }
                                this.setState({
                                    arrayPages: arrayPages,
                                })
                            })
                        })

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }

                }
            })

    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => this.getBranches())
    }

    CreateBranch() {
        document?.querySelector(".create-branch")?.classList.add("fade");
    }

    handleExportBranches() {
        const dataToExport = this.state.apiData.map(item => {
            return ({
                "institution": item.institution.name,
                "branchName": item.branchName,
                "branchCode": item.branchCode,
                "state": item.state,
                "lga": item.lga,
                "address": item.address,
                "contactEmail": item.contactEmail,
                "phoneNo": item.phoneNo,
            })
        })

        const csvData = objectToCSV(dataToExport)
        ExportToCSVFile(csvData, "branch-data.csv")

    }

    downLoadSchema() {
        const data = {
            "institution": "",
            "branchName": "",
            "branchCode": "",
            "state": "",
            "lga": "",
            "address": "",
            "contactEmail": "",
            "phoneNo": "",
        };

        const csvRows = []
        const headers = Object.keys(data)

        csvRows.push(headers.join(","));

        ExportToCSVFile(csvRows, "branch-data.csv")
    }

    getInstitutions() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }
        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({ institutions: response.body.data.institutions }, () => {
                        if (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") === -1) {
                            //Not a NIBSS User...
                            this.setState({
                                institutions: [{ "slug": sessionStorage.getItem("institution_slug"), "name": sessionStorage.getItem("institution") }],
                            })
                        }
                    });
                }
            })
    }

    componentDidMount() {
        this.getInstitutions()
        this.getBranches()

    }

    toggleSearchGroup = () => {
        this.setState({
            searchGroupState: !this.state.searchGroupState,
        })
    }

    filterRecords = () => {
        const txtSearch = document.getElementById("txtSearch");
        this.setState({ searchValue: txtSearch?.value })
        this.getBranches();
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchTitle: e?.target.value,
            searchField: e?.target.id,
        }, () => {
            this.toggleSearchGroup()
        })
    }

    render() {
        /**
         * This ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */
        if (this.state.createSuccessful) {
            this.getBranches();
            this.setState({
                createSuccessful: false,
            })
        }

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++
            //This next item ensures that a proper counter is established
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (
                <BranchManagementTableRows
                    key={item.slug}
                    apiData={item}
                    counter={itemCounter}
                    EditBranch={this.EditBranch}
                    ShowToast={this.props.ShowToast}
                    setEditData={value => this.setState({ rowData: value })}
                    setEditClicked={value => this.setState({ editClicked: value })}
                    setUpdateSuccessful={update => {
                        if (update) {
                            this.getBranches()
                        }
                    }}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}

                />
            )
        })

        const naviButtons = this.state.arrayPages && this.state.arrayPages.map(item => {
            const active = this.state.currentSelectedPage === item.id ? "active" : "";

            return (
                <button
                    key={item.id}
                    onClick={() => this.handleSelectedPage(item.id)}
                    className={active}>{item.id}
                </button>
            )
        })

        const searchGroupClass = this.state.searchGroupState ? "search-group active" : "search-group";
        const searchUserPlaceholder = "Filter by " + this.state.searchTitle

        //The items for the drop-down search...
        const SearchListItems = BranchSearchList.map(item => {
            return (
                <SearchList
                    key={item.id}
                    id={item.idVal}
                    value={item.value}
                    ChangeSearchCriteria={this.ChangeSearchCriteria}
                    searchField={this.state.searchField}
                    itemName={item.itemName}
                />
            )
        })

        return (
            <>
                <div className="title">
                    <h3>Branches</h3>
                    <div className="search">
                        <input
                            value={this.state.searchValue}
                            onChange={this.handleChange}
                            type="text"
                            id="txtSearch"
                            className="filter-search"
                            placeholder={searchUserPlaceholder}
                        />
                        <button
                            id="btnSearchBy"
                            className="btn-grey"
                            onClick={this.toggleSearchGroup}><span id="searchButtonText"><b>{this.state.searchTitle}</b></span> <i className="bx bx-chevron-down"></i>
                            <span style={{ width: 270 }} className={searchGroupClass}>
                                {SearchListItems}
                            </span>
                        </button>
                        <button
                            onClick={this.filterRecords}
                            className="btn-green t-tip"
                            id="btnSearchUser"
                            data-title="Filter records based on input">
                            <i className="icofont-ui-search"></i>
                        </button>
                    </div>
                    <div className="buttons">
                        <button type="button" className="btn-grey t-tip mr-1" data-title="Create new branch"
                            onClick={this.CreateBranch}>
                            <i className="icofont-plus"></i> Create Branch
                        </button>
                        <button id="btnExport" type="button" className="btn-green t-tip mr-1" data-title="Export to MsExcel file"
                            onClick={this.handleExportBranches}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                        <button type="button" className="btn-grey t-tip mr-1 d-none" data-title="Filter records">
                            <i className="icofont-filter"></i>
                        </button>
                        <button type="button" className="btn-grey t-tip mr-1"
                            data-title="Download schema for Bulk Upload" onClick={() => this.downLoadSchema()}>
                            <i className="icofont-download"></i>
                        </button>
                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Institution</th>
                                <th scope="col" className="align-left">Branch Name</th>
                                <th scope="col" className="align-left">Branch Code</th>
                                <th scope="col" className="align-left">State</th>
                                <th scope="col" className="align-left">Local Government</th>
                                <th scope="col" className="align-center">Number of Users</th>
                                <th scope="col" className="align-center" width="100">Status</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_branches">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <PageChanger
                    perPage={this.state.perPage}
                    handleItemsCount={this.handleItemsCount}
                />
                <div className="pagination">
                    <button className="pag" onClick={() => this.handleSelectedPage(1)}><i className="bx bx-chevrons-left"></i></button>
                    {naviButtons}
                    <button className="pag1" onClick={() => this.handleSelectedPage(this.state.lastPage)}><i className="bx bx-chevrons-right"></i></button>
                </div>
                <CreateBranchDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    institutionsArray={this.state.institutions}
                    setUpdateSuccessful={result => {
                        if (result) {
                            this.getBranches()
                        }
                    }}
                />

                {this.state.editClicked && <EditBranchDialog
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    ShowToast={this.props.ShowToast}
                    institutionsArray={this.state.institutions}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}

            </>
        )
    }
}

export default BranchManagementForm;
