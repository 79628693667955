const ExportToCSVFile = (data, fileName) => {
    let returnValue = false;

    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document?.createElement("a")
    a.setAttribute('hidden', '');
    a.setAttribute('href', url)
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    returnValue = true;

    return (
        returnValue
    )
}

export default ExportToCSVFile
