const PageChanger = (props) => {
    return (
        <div className="input-form-inline">
            <label>Items per page</label>
            <select
                type="text"
                id="perPage"
                name="perPage"
                value={props.perPage}
                onChange={props.handleItemsCount}
                className="form-control"
            >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
                <option value="100">100</option>
            </select>
        </div>
    );
}

export default PageChanger;
