import React from "react"

export default class ScheduledBillingForm extends React.Component {

    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            viewData: [],
            editData: [],
            fetchSuccessful: false,
            displayView: false,
            displayEdit: false,
        }
    }

    fetchBillingsData = () => {
        /**
         * Get the data to be displayed on the form
         */
        this.props.ShowToast("Fetches the Billing data", "success");

    }

    ExportData = () => {
        /**
         * Export the data
         * to an MSExcel
         * file after
         * filtering and
         * saving in a pre-defined
         * format... Consult the
         * requirements document(s)
         */
        this.props.ShowToast("Exports the form in a pre-defined format", "information");

    }

    RefreshForm = () => {
        this.fetchBillingsData();
    }

    render() {
        return (
            <>
                <div className="title">
                    <h3>Billing Page</h3>
                    <div className="buttons">
                        <div className="form-inline">
                            <div className="form-group">

                            </div>
                        </div>
                        <button
                            id="btnCreate"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create Scheduled Billing"
                            onClick={this.RefreshForm}>
                            <i className="bx bxs-timer"></i> Create Scheduled Billing
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportData}>
                            <i className="icofont-download"></i> Download
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">SERVICE</th>
                                <th scope="col" className="align-left">TYPE</th>
                                <th scope="col" className="align-left">RUN DATE</th>
                                <th scope="col" className="align-left">DATE CREATED</th>
                                <th scope="col" className="align-left">DATE MODIFIED</th>
                                <th scope="col" className="align-left">DOWNLOADS</th>
                                <th scope="col" className="align-left">CREATED BY</th>
                                <th scope="col" className="align-left">MODIFIED BY</th>
                                <th scope="col" className="align-left">EMAIL</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_billings">

                        </tbody>
                    </table>
                </div>

            </>
        )
    }

}

