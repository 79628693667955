import React from "react"
import CreateFile from "../../../admin/assets/App/create-file"
import ImageDialog from "../create-reference/image-dialog"
import Badges from "../../../admin/assets/App/Badges"
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import SplitDate from "../../../admin/assets/App/split-date"

class ReferenceReportsTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            itemDetails: [],
            displayImage: false,
            uploadedFile: {},
        }
    }

    componentDidMount() {
        /**
         * Convert the selected image from
         * base64 to regular file format...
         */
        this.createFile();
    }

    createFile = () => {
        if (this.props.data) {

            const ImageURL = this.props.data?.mandatePath
            // Create a FormData and append the file with "image" as parameter name
            this.setState({
                uploadedFile: CreateFile(ImageURL),
            })
        }
    }

    PreviewAttachment = () => {
        /**
         * Show the attachment
         * in the Image Dialog
         */
        this.setState({
            displayImage: true,
        })
    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = (new Date(this.props.item.dueDate).getTime() < new Date().getTime()) ? "Yes" : "No";
        const acceptRejectUser = this.props.item?.referenceLog?.acceptedByUser
            ? this.props.item?.referenceLog?.acceptedByUser?.email
            : this.props.item?.referenceLog?.rejectedByUser?.email;

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 10)}...</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td>{this.props.item?.customerInstitution?.name || sessionStorage.getItem("institution")}</td>
                    <td>{this.props.item?.customerBranch?.branchName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td title={SplitDate(this.props.item?.createdAt).dateTime}>{SplitDate(this.props.item?.createdAt).date}</td>
                    <td title={SplitDate(this.props.item?.createdAt).dateTime}>
                        {SplitDate(this.props.item?.createdAt).date}
                    </td>
                    <td>{this.props.item?.createdByUser?.email}</td>
                    <td>{this.props.item?.referenceLog?.submittedByUser?.email}</td>
                    <td>{acceptRejectUser}</td>
                    <td>{this.props.items?.referenceLog?.approvedByUser?.email}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td>{this.props.item?.dueDate}</td>
                    <td className="align-center" width="80">
                        <button
                            id="btnPreview"
                            className="btn-grey"
                            title="Preview Attachment"
                            onClick={this.PreviewAttachment}><i className="icofont-picture"></i> Preview
                        </button>
                    </td>

                </tr>
                {
                    this.state.displayImage && this.state.uploadedFile &&
                    <ImageDialog
                        isURL={false}
                        src={this.props.mandatePath}
                        setImageDisplay={value => this.setState({ displayImage: value })}
                    />
                }

            </>
        );
    }
}

export default ReferenceReportsTableRow
