const objectToCSV = (data) => {
    const csvRows = []

    //get headers
    const headers = Object.keys(data[0] || {"key": 1, "none": "else"})
    csvRows.push(headers.join(","));

    //Loop over rows
    for (const row of data) {
        const values = headers.map(header => {
            const escaped = (''+row[header]).replace(/"/g, '\\"')
            return `"${escaped}"`
        })
        csvRows.push(values.join(','))
    }

    return csvRows.join('\n');

}

export default objectToCSV;
