import React from 'react';
import ApiPath from '../../admin/assets/App/api-path';
import CreateNIBSSUserDialog from './create-nibss-user-dialog';
import UserManagementTableRows from './user-management-table-rows';
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile';
import objectToCSV from '../../admin/assets/App/object-to-csv';
import CreateInstitutionUserDialog from './create-institution-user-dialog';
import EditNIBSSUserDialog from './edit-nibss-user-dialog'
import EditInstitutionUserDialog from './edit-institution-user-dialog'
import UsersSearchList from '../../admin/assets/data/UsersSearchList';
import SearchList from '../../admin/assets/App/SearchList';
import PageChanger from '../../admin/assets/App/PageChanger';

class UserManagementForm extends React.Component {
    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            apiUserRoles: [],
            apiDataNIBSS: [],
            institutions: [],
            cbxInstitutionSelect: "",
            selectedInstitution: "",
            fetchSuccessful: false,
            createSuccessful: false,
            searchGroupState: false,
            searchTitle: "Branch Name",
            searchField: "branchName",
            searchValue: "",
            rowData: [],
            editClicked: false,
            editUserClicked: false,
            selectedInsitution: "",
            useInstitution: false,
            currentSelectedPage: 1,     //From here is for pagination management
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleExportUsers = this.handleExportUsers.bind(this)
        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
        }
        this.handleItemsCount = this.handleItemsCount.bind(this)

    }

    handleItemsCount(e) {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getNIBSSUsersData()
            this.getInsitutionUsers()
        })
    }

    componentDidMount() {
        this.getNIBSSUsersData()
        this.getInsitutions()
        this.getInsitutionUsers()
        this.getUserRoles()
        this.setState({ userCreated: false })

        //Then ensure that the selected combo is for the institution in question, each time the
        //Loading is complete...
        const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;
        if (!isNibssUser) {
            this.setState({
                cbxInstitutionSelect: sessionStorage.getItem("institution_slug"),
                useInstitution: true,
            })
        }
    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => {
            this.getNIBSSUsersData();
            this.getInsitutionUsers();
        })
    }

    getUserRoles() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }
        fetch(ApiPath + 'api/institution-user-role/fetch-institution-user-roles', options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this.setState({
                        apiUserRoles: response.body.institutionUserRoles,
                        fetchSuccessful: true,
                    })
                }
            })
    }

    getNIBSSUsersData() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(`${ApiPath}api/nibbs-user/fetch-nibbs-users?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}`, options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        apiDataNIBSS: response.body.nibbsUsers.data,
                        metaData: response.body.nibbsUsers.meta,
                    }, () => {
                        const arrayPages = [];
                        this.setState({
                            lastPage: this.state.metaData?.last_page,
                            perPage: this.state.metaData?.per_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                arrayPages.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({
                                arrayPages: arrayPages,
                            })
                        })
                    })
                }
            })
    }

    showCreateNIBSSUserDialog() {
        document?.querySelector(".create-nibss-user")?.classList.add("fade");
    }

    showCreateInstitutionUserDialog() {
        document?.querySelector(".create-institution-user")?.classList.add("fade");
    }

    EditInstitutionUser = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID)
        this.setState({
            rowData: selArray[0],
            editUserClicked: true,
        })
    }

    EditNibssUser = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiDataNIBSS.filter(itemList => itemList.slug === itemID)
        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    handleExportUsers() {
        //First, regard the filter...
        //And set the items per page to say 1000
        const previousPerPage = this.state.perPage;
        this.setState({ perPage: 1000 })

        this.getNIBSSUsersData()
        this.getInsitutionUsers()

        let exportData = [];
        let usersData = [];

        if (this.state.apiDataNIBSS) {
            exportData = this.state.apiDataNIBSS.map(item => {
                return {
                    "email": item.email,
                    "firstname": item.firstName,
                    "lastname": item.lastName,
                    "middlename": "-",
                    "institution": "NIBSS",
                    "branch": "-",
                    "userType": "NIBSS User",
                    "userRole": item.userRole.name,
                    "createdAt": item.created_at,
                    "updatedAt": item.updated_at,
                    "status": item.status,
                    "username": item.username,
                    "phone_no": "-",
                    "lastLoginDate": item.lastLoginDate ? item.lastLoginDate : "-"
                }
            })
        }

        if (this.state.apiData) {
            usersData = this.state.apiData.map(item => {
                return {
                    "email": item.email,
                    "firstname": item.first_name,
                    "lastname": item.last_name,
                    "middlename": item.middle_name,
                    "institution": item.institution?.name,
                    "branch": item.branch ? item.branch : "-",
                    "userType": item.user_type,
                    "userRole": item.userRole.name,
                    "createdAt": item.created_at,
                    "updatedAt": item.updated_at,
                    "status": item.status,
                    "username": "-",
                    "phone_no": item.phone_no,
                    "lastLoginDate": item.last_login_date ? item.last_login_date : "-",
                }
            })

        }

        const output = exportData.concat(usersData)
        const csvData = objectToCSV(output)

        ExportToCSVFile(csvData, "users.csv")

        //Reset to the previous value
        this.setState({ perPage: previousPerPage })
        this.getNIBSSUsersData()
        this.getInsitutionUsers()

    }

    handleSearchChange = e => {
        this.setState({ searchValue: e?.target.value })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            this.setState({
                selectedInstitution: this.state.cbxInstitutionSelect,
                useInstitution: true,
            }, () => {
                //Filter the selected institutions list according to the slug...
                this.getInsitutionUsers();
                (this.state.cbxInstitutionSelect === "NIBSS" || this.state.cbxInstitutionSelect === "") && this.setState({ useInstitution: false })
            })
        });

        /**
         * This is used to
         * filter the list
         * according to the
         * selected institution..
         *
         * We have 3 types
         * 1. empty ("")
         * 2. NIBSS (NIBSS)
         * 3. slug (e2oa-oidaik etc)
         * if 1, every other thing shows
         * if 2. No other insitution shows
         * if 3. Then only the selected insitution shows...
         */

    }

    getInsitutions() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }
        fetch(`${ApiPath}api/institution/fetch-institutions`, options)
            .then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                if (response && response.ok === true) {
                    this.setState({ institutions: response.body.data.institutions })
                }
            })
    }

    getInsitutionUsers() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        const institutionToUse = (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1)
            ? this.state.cbxInstitutionSelect
            : sessionStorage.getItem("institution_slug")

        const searchValue = (this.state.searchField === "branch")
            ? this.state.apiData?.find(item => item.branch?.branchName === this.state.searchField)?.slug
            : this.state.searchValue;

        fetch(`${ApiPath}api/user/fetch-institution-users?page=${this.state.currentSelectedPage}` +
            `&institution=${institutionToUse}&${this.state.searchField}=${searchValue}`, options)
            .then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok) {
                    this.setState({
                        apiData: response.body.data.users,
                        metaData: response.body.data.meta,
                        fetchSuccessful: true,
                        createSuccessful: false,
                        arrayPages: false,

                    }, () => {
                        const arrayOfPages = [];
                        this.setState({
                            perPage: this.state.metaData?.per_page,
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                arrayOfPages.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({
                                arrayPages: arrayOfPages,
                            })
                        })
                    })
                }
            })
    }

    toggleSearchGroup = () => {
        this.setState({
            searchGroupState: !this.state.searchGroupState,
        })
    }

    filterRecords = () => {
        const txtSearch = document.getElementById("txtSearch");
        this.setState({ searchValue: txtSearch?.value })
        this.getInsitutionUsers();
        this.getNIBSSUsersData()
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchTitle: e?.target.value,
            searchField: e?.target.id,
        }, () => {
            this.toggleSearchGroup()
        })
    }

    render() {

        const userType = sessionStorage.getItem("is_nibss_user");
        const institutionRow = `align-left`;
        const selectInstitutionClass = `input-group special-select ${(userType?.indexOf("NIBSS") > -1) ? '' : 'd-none'}`
        const isNibssUser = userType?.indexOf("NIBSS") > -1 ? true : false;

        let counter = 0;
        let thisCounter = 0;

        const showNIBSSButton = `btn-grey t-tip mr-1 ${sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? '' : 'd-none'}`   //Change to d-none
        /**
         * This (createSuccessful) ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */

        if (this.state.createSuccessful) {
            this.getNIBSSUsersData()
            this.getInsitutions()
            this.getInsitutionUsers()
            this.getUserRoles()

            this.setState({
                createSuccessful: false,
            })
        }

        const navButtons = this.state.arrayPages && this.state.arrayPages.map(item => {
            const active = this.state.currentSelectedPage === item.id ? "active" : "";
            return (
                <button
                    key={item.id}
                    onClick={() => this.handleSelectedPage(item.id)}
                    className={active}>{item.id}
                </button>
            )
        })

        const searchGroupClass = this.state.searchGroupState ? "search-group active" : "search-group";
        const searchUserPlaceholder = "Filter by " + this.state.searchTitle

        //The items for the drop-down search...
        const SearchListItems = UsersSearchList.map(item => {
            return (
                <SearchList
                    key={item.id}
                    id={item.idVal}
                    value={item.value}
                    ChangeSearchCriteria={this.ChangeSearchCriteria}
                    searchField={this.state.searchField}
                    itemName={item.itemName}
                />
            )
        })

        return (
            <>
                <div className="title">
                    <h3>Users</h3>
                    <div className="search">
                        <input value={this.state.searchValue} onChange={this.handleSearchChange}
                            type="text" id="txtSearch" className="filter-search" placeholder={searchUserPlaceholder}
                        />
                        <button id="btnSearchBy" className="btn-grey"
                            onClick={this.toggleSearchGroup}><span id="searchButtonText"><b>{this.state.searchTitle}</b></span> <i className="bx bx-chevron-down"></i>
                            <span className={searchGroupClass} style={{ width: 271 }}>{SearchListItems}</span>
                        </button>
                        <button
                            id="btnSearchUser"
                            className="btn-green t-tip"
                            onClick={this.filterRecords}
                            data-title="Filter records based on input">
                            <i className="icofont-ui-search"></i>
                        </button>
                    </div>
                    <div className="buttons">
                        <button type="button" className="btn-grey t-tip mr-1" data-title="Create User"
                            id="btnCreateUser" onClick={() => this.showCreateInstitutionUserDialog()}><i className="icofont-plus">
                            </i> Create Institution User
                        </button>
                        <button type="button" className={showNIBSSButton} data-title="Create NIBSS User"
                            id="btnCreateNIBSSUser" onClick={() => this.showCreateNIBSSUserDialog()}><i className="icofont-plus-circle">
                            </i> Create NIBSS User
                        </button>
                        <button type="button" className="btn-green t-tip" data-title="Export as CSV File" id="btnExport"
                            onClick={this.handleExportUsers}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className={selectInstitutionClass}>
                    <select
                        id="cbxInstitutionSelect"
                        name="cbxInstitutionSelect"
                        className="custom-select"
                        onChange={e => this.handleChange(e)}
                        value={this.state.cbxInstitutionSelect}
                        style={{ width: 300, marginTop: -10 }}>
                        <option value="NIBSS">NIBSS</option>
                        {
                            (this.state.institutions.map(item => {
                                return (<option key={item.slug} value={item.slug} title={item.slug}>{item.name}</option>)
                            }))
                        }
                    </select>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Email</th>
                                <th scope="col" className="align-left">Firstname</th>
                                <th scope="col" className="align-left">Lastname</th>
                                <th scope="col" className={institutionRow}>Institution</th>
                                <th scope="col" className="align-left">Branch</th>
                                <th scope="col" className="align-left">User Type</th>
                                <th scope="col" className="align-left">User Role</th>
                                <th scope="col" className="align-left">Last Login Date</th>
                                <th scope="col" className="align-center">Status</th>
                                <th scope="col" className="align-center" style={{ width: 150 }}>Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_users">
                            {
                                //NIBSS USERS ROWS DiSPLAY
                                (!this.state.useInstitution) && this.state.apiDataNIBSS.map(item => {
                                    counter++
                                    const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter
                                    return (
                                        <UserManagementTableRows
                                            key={item.slug}
                                            apiData={item}
                                            counter={itemCounter}
                                            institutionRowVisible={true}
                                            setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}
                                            EditUser={this.EditNibssUser}
                                            ShowToast={this.props.ShowToast}
                                            setIsLoaderVisible={this.props.setIsLoaderVisible}
                                            isInstitutionUser={false}
                                            isNibssUser={true}

                                        />
                                    )
                                })
                            }
                            {
                                //INSTITUTION USERS ROWS DISPLAY
                                (this.state.useInstitution) && this.state.apiData.map(userAPI => {
                                    thisCounter++;
                                    const anotherCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + thisCounter

                                    return (
                                        <UserManagementTableRows
                                            key={userAPI.slug}
                                            counter={anotherCounter}
                                            apiData={userAPI}
                                            institutionRowVisible={true}
                                            setIsCreateSuccessful={res => this.setState({ createSuccessful: res })}
                                            EditUser={this.EditInstitutionUser}
                                            ShowToast={this.props.ShowToast}
                                            setIsLoaderVisible={this.props.setIsLoaderVisible}
                                            isInstitutionUser={true}
                                            rolesArray={this.state.apiUserRoles}

                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <PageChanger
                    perPage={this.state.perPage}
                    handleItemsCount={this.handleItemsCount}
                />
                <div className="pagination">
                    <button className="pag" onClick={() => this.handleSelectedPage(1)}><i className="bx bx-chevrons-left"></i></button>
                    {navButtons}
                    <button className="pag1" onClick={() => this.handleSelectedPage(this.state.lastPage)}><i className="bx bx-chevrons-right"></i></button>
                </div>

                <CreateNIBSSUserDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}
                />
                <CreateInstitutionUserDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={val => this.setState({ createSuccessful: val })}
                    institutionsArray={this.state.institutions}
                    rolesArray={this.state.apiUserRoles}
                    isNibssUser={isNibssUser}
                    selectedInstitution={this.state.selectedInstitution}
                />

                {this.state.editClicked && <EditNIBSSUserDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}


                {this.state.editUserClicked && <EditInstitutionUserDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsEditSuccessful={val => this.setState({ createSuccessful: val })}
                    institutionsArray={this.state.institutions}
                    rolesArray={this.state.apiUserRoles}
                    rowData={this.state.rowData}
                    isNibssUser={isNibssUser}
                    editClicked={this.state.editUserClicked}
                    setEditClicked={value => this.setState({ editUserClicked: value })}
                    selectedInstitution={this.state.selectedInstitution}
                />}
            </>
        )

    }
}

export default UserManagementForm;
