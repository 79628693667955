import React from 'react'
import CreateUserRoleDialog from './create-user-role-dialog'
import UserRoleTableRow from './user-role-table-row'
import ViewUserRoleDialog from './ViewUserRoleDialog'
import ApiPath from '../../admin/assets/App/api-path'
import EditUserRolesDialog from './edit-user-roles-dialog'
import UserRoleButtons from './UserRoleButtons'
export class UserRolesForm extends React.Component {
    constructor() {
        super()
        this.state = {
            apiData: [],
            dialogState: false,
            sentApiData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            rowData: [],
            editClicked: false,
        }

    }

    getUserRoles() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            }
        }

        fetch(ApiPath + 'api/institution-user-role/fetch-institution-user-roles', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                //Check the response...
                if (response) {
                    if (response.ok === true) {
                        this?.setState({
                            apiData: response.body.institutionUserRoles,
                            fetchSuccessful: true,
                            createSuccessful: false,
                        })
                    } else {
                        this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                        return;
                    }

                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    EditUserRoles = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID);
        /*Set state values */
        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    componentDidMount() {
        this.getUserRoles()
    }

    RefreshForm = () => {
        this.getUserRoles()
    }

    render() {
        /**
         * This ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */
        if (this.state.createSuccessful) {
            this.getUserRoles();
        }

        //Load the table rows each time the table refreshes
        let counter = 0;
        const TableRows = this.state.apiData.map(item => {
            counter++;

            return (<UserRoleTableRow
                key={counter}
                counter={counter}
                data={item}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                EditUserRoles={this.EditUserRoles}

            />)

        })

        return (
            <>
                <div className="title">
                    <h3>User Roles</h3>
                    <UserRoleButtons RefreshForm={this.RefreshForm} />
                    <button id="testButton2" className="d-none"
                    onClick={() => {
                        this.getUserRoles();
                        this.EditUserRoles("someSlugValue");
                        this.RefreshForm();
                    }}></button>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Role Name</th>
                                <th scope="col" className="align-center">Services</th>
                                <th scope="col" className="align-center">Number of Users</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_user_roles">
                            {TableRows}
                        </tbody>
                    </table>
                </div>

                <CreateUserRoleDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}

                />

                {this.state.editClicked && <EditUserRolesDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}

                />}

                <ViewUserRoleDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                />

            </>
        )
    }
}
