export const Summaries = ({ refreshSummaries }) => {
    return (
        <div className="summaries">
            <div className="title">
                <h3>Summaries</h3>
                <button id="btnRefreshTable" type="button" onClick={refreshSummaries}><i
                    className="icofont-refresh"></i> Refresh</button>
            </div>
            <div className="table-container">
                <table><caption></caption>
                    <thead>
                        <tr>
                            <th scope="col" className="align-left">Users</th>
                            <th scope="col" className="align-left">Total</th>
                            <th scope="col" className="align-left">Online</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>NIBSS Admin Users</td>
                            <td>5</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>NIBSS Users</td>
                            <td>213</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>Institution Admin Users</td>
                            <td>44</td>
                            <td>21</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
