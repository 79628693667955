import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";

export default class CreateBillingsDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            cbxService: "",
            roleServices: [],
            cbxDateFrom: "",
            cbxDateTo: "",
            chkSendEmail: false,
            txtSpecialEmail: "",
        }

        this.txtAccountUser = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;
    }

    componentDidMount() {
        this.fetchService();
    }

    fetchService = () => {
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', Options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        roleServices: response.body.services,
                    })
                }
            })
    }

    handleChange = e => {
        const { name, value, type, checked } = e.target;
        type === "checked" ? this.setState({
            [checked]: value,
        }) : this.setState({
            [name]: value,
        })
    }

    closeForm = () => {
        this.props.setDisplayCreateDialog(false)
    }

    handleSubmit = () => {
        /**
         * Creates a
         * new billing
         * record after
         * validating the
         * inputs
         */
        if (this.state.cbxService === "") {
            this.props.ShowToast("Billing Service required. Select from list.", "exclamation");
            return;
        }
        if (this.state.cbxDateFrom === "") {
            this.props.ShowToast("'From' Date required!", "exclamation");
            return;
        }
        if (this.state.cbxDateTo === "") {
            this.props.ShowToast("'From' Date required!", "exclamation");
            return;
        }

        //Else proceed to update...
        this.props.setIsLoaderVisible(true);

        const updateData = {
            service: this.state.cbxService,
            fromDate: this.state.cbxDateFrom,
            toDate: this.state.cbxDateTo,
            emails: this.state.txtSpecialEmail,
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            body: JSON.stringify(updateData),
        }

        fetch(ApiPath + 'api/branch/create-branch', options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        this.props.ShowToast("Billings Record generated successfully!", "success")
                        this.props.setCreateSuccessful(true)
                        this.props.setDisplayCreateDialog(false)
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    render() {

        const dialogClass = this.props.displayCreateDialog ? "dialog-background fade" : "dialog-background";
        const Services = this.state.roleServices && this.state.roleServices.map(item => {
            return (
                <option
                    key={item.id}
                    value={item.slug}>{item.name}</option>
            )
        })

        return (
            <div className={dialogClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 id="dialog-title" className="create"><i className="bx bxs-receipt"></i> Create Billings</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control"
                                    value={this.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Billing Details</h3>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group col-md">
                                <label><span>*</span>Billing Service for</label>
                                <select
                                    id="cbxService"
                                    name="cbxService"
                                    className="custom-select"
                                    value={this.state.cbxService}
                                    onChange={this.handleChange}>
                                    <option value=""></option>
                                    {Services}
                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select Service from list</label>
                            </div>
                        </div>
                        <div className="form-row mb-2">
                            <div className="input-group col-md">
                                <label><span>*</span>Date (from)</label>
                                <input
                                    type="date"
                                    id="cbxDateFrom"
                                    name="cbxDateFrom"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.cbxDateFrom} />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select/Enter Start Date</label>
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Date (to)</label>
                                <input
                                    type="date"
                                    id="cbxDateTo"
                                    name="cbxDateTo"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.cbxDateTo} />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select/Enter End Date</label>
                            </div>
                        </div>
                        <div className="form-row-3">
                            <div className="inline-input-group">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="chkSendEmail"
                                        id="chkSendEmail"
                                        className="form-control"
                                        value={this.state.chkSendEmail}
                                        onChange={this.handleChange}
                                    /> Send Email
                                </label>
                            </div>
                        </div>
                        <div className="form-row-3x mb-3">
                            <div className="input-group">
                                <textarea
                                    type="text"
                                    id="txtSpecialEmail"
                                    name="txtSpecialEmail"
                                    className="form-control"
                                    rows="5"
                                    value={this.state.txtSpecialEmail}
                                    onChange={this.handleChange}
                                    disabled={!this.state.chkSendEmail}
                                />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Separate Multiple Emails by commas</label>
                            </div>

                        </div>
                    </div>

                    <hr className="mb-2" />
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" name="btnUpdateInstitution" type="button"
                            onClick={this.handleSubmit} title="Create">
                            <i className="icofont-upload-alt"></i> Create Billing
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}