import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";

import { Helmet } from "react-helmet";
import React, { useState } from 'react'
import InstitutionsForm from "./institutions-form";
import Footer from "../../admin/assets/App/footer";

export const Institutions = (props) => {

    const [mainClass, setMainClass] = useState("main");

    const createInstitution = () => {
        document?.querySelector(".create")?.classList.add("fade");
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Institutions Management | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Institution Management" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-institution"></i>Institution Management</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <div className="title">
                                <h3>Institutions</h3>
                                <div className="buttons">
                                    <button
                                        id="btnCreateInstitution"
                                        type="button"
                                        className="btn-grey t-tip mr-1"
                                        data-title="Create New Institution"
                                        onClick={createInstitution}>
                                        <i className="icofont-plus"></i> Create Institution
                                    </button>
                                    <button
                                        id="btnExport"
                                        type="button"
                                        className="btn-green t-tip mr-1 d-none"
                                        data-title="Export records to MsExcel file">
                                        <i className="icofont-airplane-alt"></i> Export
                                    </button>
                                    <button
                                        id="btnFilter"
                                        type="button"
                                        className="btn-grey t-tip mr-1 d-none" data-title="Filter records">
                                        <i className="icofont-filter"></i>
                                    </button>
                                    <button
                                        id="btnDownloadSampleFile"
                                        type="button"
                                        className="btn-grey t-tip mr-1 d-none" data-title="Download schema for Bulk Upload"><i className="icofont-download"></i>
                                    </button>

                                </div>
                            </div>

                            <InstitutionsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />

                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    )
}
