import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'

class CreateInstitutionTypesDialog extends React.Component {
    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            txtInstitutionType: "",
            txtInstitutionTypeCode: "",
            cbxEReference: false,
            cbxTrappedCards: false,
            cbxEDMMS: false,
            cbxEClips: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`
        }

    }

    handleChange(event) {
        const { name, value, type, checked } = event.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit() {
        //Use the provided API to update the records after validating...
        try {

            if (this.state.txtInstitutionType === "") {
                this.props.ShowToast("Institution Type required.", "exclamation")
                document.getElementById("txtInstitutionType").focus()
                return;
            }
            if (this.state.txtInstitutionTypeCode === "") {
                this.props.ShowToast("Institution Type Code of not more than 10-digits required.", "exclamation")
                document.getElementById("txtInstitutionTypeCode").focus()
                return;
            }

            //Else, good to go
            this.props.setIsLoaderVisible(true)
            const servicesArray = [];

            if (this.state.cbxEReference) {
                servicesArray.push(1)
            }
            if (this.state.cbxTrappedCards) {
                servicesArray.push(2)
            }
            if (this.state.cbxEDMMS) {
                servicesArray.push(3)
            }
            if (this.state.cbxEClips) {
                servicesArray.push(4)
            }

            //Set the data first...
            const data = {
                "name": this.state.txtInstitutionType,
                "code": this.state.txtInstitutionTypeCode,
                "services": servicesArray
            };
            const options = {
                method: 'POST',
                headers: this.headersData,
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/institution-type/create-institution-type', options)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            this.props.ShowToast("Institution Type created successfully!", "success")
                            this.props.setIsLoaderVisible(false)
                            this.props.setIsCreateSuccessful(true)
                            document.querySelector(".create-form").classList.remove("fade")

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })
        } catch (err) {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(err, "exclamation")
        }
    }

    closeForm() {
        document?.querySelector(".create-form")?.classList.remove("fade")
    }

    render() {
        return (
            <div className="dialog-background create-form">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-building-alt"></i> Create Institution Type</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" />

                        <div className="form-row-2-1 mb-3">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution Type</label>
                                <input
                                    type="text"
                                    name="txtInstitutionType"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtInstitutionType}
                                    required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Type Code</label>
                                <input
                                    type="text"
                                    name="txtInstitutionTypeCode"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtInstitutionTypeCode}
                                    required />
                            </div>
                        </div>
                        <div className="form-row-3 mb-3">
                            <div className="input-group">
                                <label>Services</label>
                                <div className="checkbox-group">
                                    <div className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="cbxEReference"
                                                checked={this.state.cbxEReference}
                                                onChange={this.handleChange} />
                                            e-Reference
                                        </label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="cbxTrappedCards"
                                                checked={this.state.cbxTrappedCards}
                                                onChange={this.handleChange} />
                                            Trapped Cards
                                        </label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label><input type="checkbox" name="cbxEDMMS" id="cbxEDMMS" checked={this.state.cbxEDMMS} onChange={this.handleChange} /> e-DMMS</label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label><input type="checkbox" name="cbxEClips" checked={this.state.cbxEClips} onChange={this.handleChange} /> e-Clips</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" id="btnUpdateInstitution" name="btnUpdateInstitution" type="button" onClick={this.handleSubmit}>
                            <i className="icofont-upload-alt"></i> Create
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit"></i> Cancel
                        </button>
                        <button id="testButton" className="d-none" onChange={this.handleChange}></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateInstitutionTypesDialog
