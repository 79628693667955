import React from "react";
import ApiPath from './../../admin/assets/App/api-path'
import UserRoleCheckGroup from "./user-role-checkgroup";
export class EditUserRolesDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            txtRoleID: "",
            txtRoleName: "",
            txtDescription: "",
            cbxEReference: false,
            cbxTrappedCards: false,
            cbxEDMMS: false,
            cbxEClips: false,
            cbxERefOperator: false,
            cbxERefAuthorizer: false,
        }

        this.updateUserRole = this.updateUserRole.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    ClearERefRoleServices = () => {
        if (!this.state.cbxEReference) {
            this.setState({
                cbxERefOperator: false,
                cbxERefAuthorizer: false,
            })
        }
    }

    ValidateERefServiceRoles = () => {
        let proceed = false;
        const rolesArray = [];

        if (this.state.cbxEReference) {
            if (!this.state.cbxERefAuthorizer && !this.state.cbxERefOperator) {
                //Issue
                this.props.ShowToast("Select an Option for eReference to proceed.", "exclamation");

            } else {
                proceed = true;
                if (this.state.cbxERefOperator) {
                    rolesArray.push(1)
                }
                if (this.state.cbxERefAuthorizer) {
                    rolesArray.push(2)
                }
            }
        }

        //Check to see which ones match...
        return {
            ok: proceed,
            rolesArray: rolesArray,
        }

    }

    updateUserRole() {

        const servicesArray = [];

        if (this.state.txtRoleName === "") {
            this.props.ShowToast("Enter a valid Role Name before proceeding.", "exclamation")
            return;
        } else if (this.state.txtRoleID === "") {
            this.props.ShowToast("Enter a valid Role ID before proceeding.", "exclamation")
            return;
        } else if (this.state.txtDescription === "") {
            this.props.ShowToast("Enter the Role Description.", "exclamation")
            return;
        }

        //Later we will repeat this
        // for other services
        // and get the same thing
        // off for them...
        const eRefItems = this.ValidateERefServiceRoles()
        if (!eRefItems.ok) {
            return
        } else {
            servicesArray.push({
                id: 1,
                serviceRoles: eRefItems.rolesArray,
            })
        }

        //Else proceed...
        const data = {
            "slug": this.props.rowData?.slug,
            "name": this.state.txtRoleName,
            "roleId": this.state.txtRoleID,
            "description": this.state.txtDescription,
            "services": servicesArray,
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            body: JSON.stringify(data),
        }

        this.props.setIsLoaderVisible(true)
        fetch(ApiPath + "api/institution-user-role/update-institution-user-role", options)
            ?.then(response => { return response.json() })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response.ok) {
                    this.props.ShowToast("User role updated successfully", "success")
                    this.props.setIsEditSuccessful(true)
                    this.closeForm()
                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    handleChange(e) {
        const { name, value, type, checked } = e.target;
        (type === "checkbox") ? this.setState({ [name]: checked }, () => {
            if (name === "cbxEReference") {
                //Disable the other related ones if disabled
                this.ClearERefRoleServices();
            }
        }) : this.setState({ [name]: value })
    }

    closeForm() {
        this.props.setEditClicked(false)
    }

    componentDidMount() {
        //component did mount...
        this.setState({
            txtRoleID: this.props.rowData?.roleId,
            txtRoleName: this.props.rowData?.name,
            txtDescription: this.props.rowData?.description,
        })
        /**
         * Populate the checkboxes as required...
         */

    }

    render() {
        const displayClass = (this.props.editClicked) ? "dialog-background edit-role fade" : "dialog-background edit-role";

        return (
            <div className={displayClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="edit"><i className="icofont-user-alt-5"></i> Edit User Role</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row-1-2">
                            <div className="input-group">
                                <label>Role ID</label>
                                <input
                                    type="text"
                                    id="txtRoleID"
                                    name="txtRoleID"
                                    className="form-control"
                                    onChange={e => this.handleChange(e)}
                                    value={this.state.txtRoleID} />
                            </div>
                            <div className="input-group">
                                <label>Role Name</label>
                                <input
                                    type="text"
                                    id="txtRoleName"
                                    name="txtRoleName"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtRoleName} />
                            </div>
                        </div>
                        <div className="form-row-3x mb-2">
                            <div className="input-group">
                                <label>Description</label>
                                <textarea
                                    id="txtDescription"
                                    name="txtDescription"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtDescription}
                                    maxLength="499"
                                    rows="5"
                                >
                                </textarea>
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Service & Role Services</h3>
                        <UserRoleCheckGroup
                            cbxEReference={this.state.cbxEReference}
                            cbxTrappedCards={this.state.cbxTrappedCards}
                            cbxEDMMS={this.state.cbxEDMMS}
                            cbxEClips={this.state.cbxEClips}
                            handleChange={this.handleChange}
                        />
                    </div>
                    <div className="dialog-footer">
                        <button
                            type="button" className="btn-green mr-1" id="btnCreate"
                            name="btnCreate"
                            onClick={() => this.updateUserRole()}>
                            <i className="icofont-upload-alt"></i> Update User Role
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={() => this.closeForm()}><i className="icofont-exit"></i> Close</button>
                        <button id="testButton" className="d-none"
                            onClick={() => {
                                this.componentDidMount();
                            }} onChange={this.handleChange}>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditUserRolesDialog
