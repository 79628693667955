import ApiPath from "../../admin/assets/App/api-path";
import SplitDate from "../../admin/assets/App/split-date";

const UserManagementTableRows = (props) => {

    function handleEditRowClicked(value) {
        props.EditUser(value)
    }

    function handleEnableDisable(slug, userType) {
        const status = props.apiData.status === "active" ? "deactivate" : "activate";
        const confirm = window.confirm(`Are you sure you wish to ${status} the selected user?`);

        if (confirm) {
            const data = { "slug": slug }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
                body: JSON.stringify(data),
            }

            const endpoint = userType === "NIBSS" ? "api/nibbs-user/change-user-status" : "api/user/change-user-status";
            fetch(ApiPath + endpoint, options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        props.ShowToast(`User ${status}d successfully!`, "success")
                        props.setIsCreateSuccessful(true)
                    } else {
                        props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                props.setIsLoaderVisible(false)
                props.ShowToast(error.message, "exclamation")
            })
        }
    }

    const displayInstitutionRow = (props.institutionRowVisible) ? 'align-left' : 'align-left d-none';
    const badgeType = (props.apiData.status === "active") ? 'badge-success' : 'badge-danger'
    const icofontImage = (props.apiData.status === "active") ? 'icofont-play-alt-1' : 'icofont-stop'
    const eyeImage = (props.apiData.status === "active") ? 'icofont-eye-blocked' : 'icofont-eye'

    return (
        <tr>
            <td className="align-center">{ props.counter }.</td>
            <td>{ props.apiData.email}</td>
            <td>{ props.apiData.firstName || props.apiData.first_name}</td>
            <td>{ props.apiData.lastName || props.apiData.last_name}</td>
            <td className={displayInstitutionRow}>{ (props.isInstitutionUser) ? props.apiData.institution?.name : "NIBSS" }</td>
            <td>{ (props.isNibssUser) ? "-" : props.apiData.branch?.branchName }</td>
            <td>{ (props.isNibssUser) ? "NIBSS User" : props.apiData.user_type }</td>
            <td>{ props.apiData.userRole.name }</td>
            <td>{ SplitDate(props.apiData?.lastLoginDate).dateTime || SplitDate(props.apiData.last_login_date).dateTime }</td>
            <td title={ props.apiData.status }><span className={ badgeType }><i className={ icofontImage }></i></span></td>
            <td className="align-center">
                <button id="editBtnRow" className="btn-grey mr-1" onClick={() => handleEditRowClicked(props.apiData.slug)}>Edit</button>
                <button
                    className="btn-grey"
                    title="Enable/Disable"
                    onClick={() => handleEnableDisable(props.apiData.slug, (props.isInstitutionUser) ? props.apiData.institution.name : "NIBSS")}>
                        <i className={ eyeImage } style={{ fontSize: '1.4em'}}></i>
                </button>
            </td>
        </tr>

    );
}

export default UserManagementTableRows;
