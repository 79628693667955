import React from "react"
class UserRoleButtons extends React.Component {

    constructor() {
        super()
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        document?.querySelector(".create-role")?.classList.add("fade");
    }

    RefreshForm = () => {
        this.props.RefreshForm()
    }

    render() {
        return (
            <div className="buttons">
                <button id="btnRefresh" type="button" className="btn-grey t-tip mr-1" data-title="Refresh" onClick={this.RefreshForm}>
                    <i className="icofont-refresh"></i> Refresh
                </button>
                <button type="button" className="btn-grey t-tip mr-1" data-title="Create User Role"
                    id="btnCreateUserRole" onClick={this.handleClick}><i className="icofont-plus">
                    </i> Create User Role
                </button>
                <button type="button" className="btn-green d-none t-tip" data-title="Export to MsExcel file" id="btnExport">
                    <i className="icofont-airplane-alt"></i> Export
                </button>
            </div>
        )
    }
}

export default UserRoleButtons
