import { useState } from "react";

const UserRoleCheckGroup = (props) => {

    const [refDisabled, setRefDisabled] = useState(false);

    const checkChange = e => {
        if (e.target.name === "cbxEReference") {
            setRefDisabled(() => e?.target.checked)

            //Also, ensure that the children are disabled
            if (refDisabled) {
                document.getElementById("cbxERefOperator")?.setAttribute("checked", false);
                document.getElementById("cbxERefAuthorizer")?.setAttribute("checked", false);
            }
        }
    }

    const ERefClass = refDisabled ? "checkbox-container no-border bg-success" : "checkbox-container no-border bg-danger";

    return (
        <div className="form-row-3 mb-3">
            <div className="input-group">
                <div className="mb-2"></div>
                <table className="checkbox-group" style={{ width: 660 }}>
                    <caption></caption>
                    <thead>
                        <tr>
                            <th scope="col" width={220}>Services</th>
                            <th scope="col" width={440}>Roles Services</th>
                        </tr>
                    </thead>
                    <tr className="checkbox-row">
                        <td className="checkbox-container no-border" width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    id="cbxEReference"
                                    name="cbxEReference"
                                    checked={props.cbxEReference}
                                    onChange={e => {
                                        checkChange(e)
                                        props.handleChange(e)
                                    }} />
                                e-Reference
                            </label>
                        </td>
                        <td className={ERefClass} width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    id="cbxERefOperator"
                                    name="cbxERefOperator"
                                    checked={props.cbxERefOperator}
                                    onChange={props.handleChange}
                                    disabled={!refDisabled} />
                                Operator
                            </label>
                        </td>
                        <td className={ERefClass} width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    id="cbxERefAuthorizer"
                                    name="cbxERefAuthorizer"
                                    checked={props.cbxERefAuthorizer}
                                    onChange={props.handleChange}
                                    disabled={!refDisabled} />
                                Authorizer
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="checkbox-container" width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cbxTrappedCards"
                                    checked={props.cbxTrappedCards}
                                    onChange={props.handleChange} />
                                Trapped Cards
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="checkbox-container" width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cbxEDMMS"
                                    id="cbxEDMMS"
                                    checked={props.cbxEDMMS}
                                    onChange={props.handleChange} />
                                e-DMMS
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="checkbox-container" width={220}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cbxEClips"
                                    checked={props.cbxEClips}
                                    onChange={props.handleChange} />
                                e-Clips
                            </label>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default UserRoleCheckGroup;
