import React from "react"
import ApiPath from "../../admin/assets/App/api-path";
import ValidateEmail from "../../admin/assets/App/validate-email";

class EditInstitutionUserDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            cbxInstitution: "",
            cbxBranch: "",
            txtMiddlename: "",
            cbxUserRole: "",
            txtEmail: "",
            txtLastname: "",
            txtFirstname: "",
            txtPhone: "",
            cbxUserType: "",
            institutionsData: [],
            branchData: [],
            filteredBranchData: [],
            userRoleData: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
        }
    }

    componentDidMount() {
        if (this.props.rowData) {
            //First, get the branch and institutions data...
            this.getBranches();
            this.setState({ institutionsData: this.props?.institutionsArray }, () => {
                //We can now set the others after these basics...
                this.setState({
                    cbxInstitution: this.props.rowData.institution?.slug,
                    cbxBranch: this.props.rowData?.branch?.slug,
                    cbxUserRole: this.props.rowData?.userRole?.slug,
                    txtEmail: this.props.rowData?.email,
                    txtLastname: this.props.rowData?.firstName,
                    txtFirstname: this.props.rowData?.lastName,
                    txtMiddlename: this.props.rowData?.middleName,
                    txtPhone: this.props.rowData?.phone_no,
                    cbxUserType: this.props.rowData?.user_type,
                })
            })

        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (name === "cbxInstitution") {
                //filter the branches to match the selected institution...
                this.filterBranchData();
            }
        })
    }

    filterBranchData = () => {
        this.setState({
            filteredBranchData: this.state.branchData.filter(item => item.institution?.slug === this.state.cbxInstitution)
        })
    }

    handleSubmit() {
        try {

            //Inputs validation before updates...
            if (this.state.cbxUserRole === "") {
                this.props.ShowToast("User Role Required.", "exclamation")
                return
            } else if (this.state.cbxInstitution === "") {
                this.props.ShowToast("Institution required.", "exclamation")
                return;
            } else if (this.state.cbxUserType === "") {
                this.props.ShowToast("Select a valid User type before proceeding.", "exclamation")
                return;
            }

            //The texts...
            if (this.state.txtFirstname === "") {
                this.props.ShowToast("First Name required", "exclamation");
                return;
            } else if (this.state.txtLastname === "") {
                this.props.ShowToast("Last Name required!", "exclamation");
                return;
            } else if (!ValidateEmail(this.state.txtEmail)) {
                this.props.ShowToast("Valid Email Address required", "exclamation");
                return;
            }


            ///If all checks out, proceed...
            this.props.setIsLoaderVisible(true);
            const data = {
                "institution": this.state.cbxInstitution,
                "userType": this.state.cbxUserType,
                "branch": this.state.cbxBranch,
                "lastName": this.state.txtLastname,
                "userRole": this.state.cbxUserRole,
                "email": this.state.txtEmail,
                "firstName": this.state.txtFirstname,
                "middleName": this.state.txtMiddlename,
                "phoneNo": this.state.txtPhone,
                "slug": this.props.rowData.slug,

            };
            const options = {
                method: 'POST',
                headers: this.headersData,
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/user/update-institution-user', options)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok) {
                            //Success
                            this.props.setIsEditSuccessful(true)
                            this.props.ShowToast("Institution User updated successfully!", "success")
                            this.props.setEditClicked(false)
                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid inputs", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                    this.props.setIsLoaderVisible(false)
                })

        } catch (error) {
            //Error message, if it
            //occurs with the
            //subroutine generally
            this.props.ShowToast(error, "exclamation")
            this.props.setIsLoaderVisible(false)

        }
    }

    getBranches() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }
        fetch(`${ApiPath}api/branch/fetch-branches`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        branchData: response.body.branches.data,
                    })
                }
            })
    }

    closeForm() {
        this.props.setEditClicked(false)
    }

    render() {
        const displayBranch = (this.state.cbxUserType !== "user") ? "input-group d-none" : "input-group"
        const dialogClass = (this.props.editClicked) ? "dialog-background edit-insitution-user fade" : "dialog-background edit-insitution-user"

        return (
            <div className={dialogClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="edit"><i className="icofont-users"></i> Edit Institution User</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control"
                                    value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" />
                        <h3 className="row-title">User Details</h3>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution User Type</label>
                                <select
                                    name="cbxUserType"
                                    className="custom-select"
                                    value={this.state.cbxUserType}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select User Type</option>
                                    {(this.props.isNibssUser || this.state.cbxUserType === "admin") &&
                                        <option value="admin">Institution Admin</option>}
                                    <option value="user">Institution User</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>User Role</label>
                                <select
                                    name="cbxUserRole"
                                    className="custom-select"
                                    value={this.state.cbxUserRole}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select User Role</option>
                                    {//Return an array of User Roles
                                        this.props.rolesArray.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Institution</label>
                                <select
                                    id="cbxInstitution"
                                    name="cbxInstitution"
                                    className="custom-select"
                                    onChange={this.handleChange}
                                    value={this.state.cbxInstitution}
                                    disabled
                                >
                                    <option value="">Select Institution</option>
                                    {//Return an array of institutions
                                        this.props.institutionsArray && this.props.institutionsArray?.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className={displayBranch}>
                                <label>Branch</label>
                                <select
                                    className="custom-select"
                                    name="cbxBranch"
                                    value={this.state.cbxBranch}
                                    onChange={this.handleChange}
                                >
                                    <option value="" className="bg-silver">Select Branch</option>
                                    {//Return an array of institution Branch, based on the selected institution above.
                                        this.state.filteredBranchData?.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.branchName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>First Name</label>
                                <input type="text" name="txtFirstname" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtFirstname} required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Last Name</label>
                                <input type="text" name="txtLastname" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtLastname} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label>Middle Name</label>
                                <input type="text" name="txtMiddlename" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtMiddlename} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Email Address</label>
                                <input type="email" name="txtEmail" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtEmail} required />
                            </div>
                            <div className="input-group col-md">
                                <label>Phone</label>
                                <input type="phone" name="txtPhone" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtPhone} />
                            </div>
                        </div>


                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnUpdateInstitution"
                            name="btnUpdateInstitution"
                            type="button"
                            onClick={this.handleSubmit}
                            title="Create">
                            <i className="icofont-upload-alt"></i> Update User
                        </button>
                        <button
                            type="button"
                            className="btn-grey mr-2"
                            id="btnCancel"
                            onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditInstitutionUserDialog;
