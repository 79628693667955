import { Link } from "react-router-dom"
import { useHistory } from "react-router";

const ErrorPage = () => {
    const history = useHistory();

    const PreviousPageClick = e => {
        e?.preventDefault();
        history?.goBack();
    }

    const SignOut = e => {
        e?.preventDefault();
        const userType = sessionStorage.getItem("is_nibss_user");

        sessionStorage.setItem("session_token_timestamp", null);
        sessionStorage.setItem("session_valid_user", null);
        sessionStorage.setItem("session_token", null);
        sessionStorage.setItem("session_locked_state", null);
        sessionStorage.setItem("session_first_name", null);
        sessionStorage.setItem("session_last_name", null);
        sessionStorage.setItem("session_user_type", null);
        sessionStorage.setItem("institution", null);
        sessionStorage.setItem("customer_branch", null);
        sessionStorage.setItem("customer_branch_slug", null);
        sessionStorage.setItem("institution_slug", null);

        //Sign out
        /**
         * This is determined by the kind of user logged in
         * NIBSS user
         * or
         */
        let loc = "./../";
        if (userType !== null) {
            loc = userType.indexOf("NIBSS") > -1 ? "./../nibss-user-login" : "./../";
        }
        window.location.href = loc;
    }

    return (
        <div className="page-background">
            <div className="error-page">
                <h1>404</h1>
                <h2>Document Management Hub Portal</h2>
                <h4>Oops! We can't seem to find the page you're looking for. While we look into it...</h4>

                <div className="links">
                    <Link to="/" id="prevPage" onClick={PreviousPageClick}>Go Back</Link>
                    <span className="spacer"></span>
                    <Link to="/" id="signOut" onClick={SignOut}>Sign Out</Link>
                </div>
                <img src="/assets/img/svg/not-found-404.svg" alt="" />

            </div>
        </div>

    );
}

export default ErrorPage;
