import React from "react";
import IncomingReferencesTableRow from "./incoming-references-table-row";
import ApiPath from "../../../admin/assets/App/api-path";
import SplitDate from "../../../admin/assets/App/split-date";
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";
import ReferenceSearchList from "../../../admin/assets/data/ReferenceSearchList"
import PageChanger from "../../../admin/assets/App/PageChanger";
import ViewReferenceDialog from "../../App/outgoing-references/view-reference-dialog"
import SearchComponent from "../../../admin/assets/App/SearchComponent";

class IncomingReferenceForm extends React.Component {
    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            fetchSuccessful: false,
            searchGroupState: false,
            searchTitle: "Cust Acct Name",
            searchField: "customerAccountName",
            searchValue: "",
            displayView: false,
            displayEdit: false,
            apiData: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.handleItemsCount = this.handleItemsCount.bind(this)
        this.RefreshForm = this.RefreshForm.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    handleItemsCount(e) {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getIncomingReferences()
        })
    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => this.getIncomingReferences())
    }

    handleChange(e) {
        this.setState({ searchValue: e?.target.value })
    }

    ExportData = () => {
        /**
         * Export the data
         * to an MSExcel
         * file after
         * filtering and
         * saving in a pre-defined
         * format... Consult the
         * requirements document(s)
         * We need to obtain the data then do the necessary...
         *
         * First, ensure that the
         * items per page state is set to a very
         * high value
         */
        const previousPerPage = this.state.perPage;
        this.setState({ perPage: 20000 }, () => this.getIncomingReferences())

        const dataToExport = [];
        this.state.apiData.forEach(item => {
            //First, get individual reference details
            this.getIndividualReference(item.slug);

            var Data = this.state.exportData;

            var thisObject = {
                "Reference No": Data?.referenceNo,
                "Status": Data?.actionStatus,
                "Customer Account": Data?.customerAccountNumber,
                "Customer Name": Data?.customerAccountName,
                "Customer Bank": sessionStorage.getItem("institution"),
                "Customer Branch": Data?.customerBranch?.branchName,
                "Referee Account Name": Data?.refereeAccountName,
                "Referee Bank": Data?.receiverInstitution?.name,
                "Referee Branch": Data?.receiverBranch?.branchName,
                "Referee A/C No": Data?.refereeAccountNumber,
                "Created By": Data?.createdByUser?.email,
                "Creation Date": SplitDate(Data?.createdAt).date,
                "Submitted/Rejected": Data?.referenceLog?.submittedByUser ? "Submitted" : "Rejected",
                "Submitted/Rejected Presenting Time": Data?.referenceLog?.submittedByUser
                    ? SplitDate(Data?.referenceLog?.submittedByUser?.updated_at).date
                    : SplitDate(Data?.referenceLog?.rejectedByUser?.updated_at).date,
                "Accepted/Rejected Receiving By": Data?.referenceLog?.acceptedByUser
                    ? Data?.referenceLog?.acceptedByUser?.email
                    : Data?.referenceLog?.rejectedByUser?.email,
                "Accepted/Rejected Receiving Time": Data?.referenceLog?.acceptedByUser
                    ? SplitDate(Data?.referenceLog?.acceptedByUser?.updated_at).date
                    : SplitDate(Data?.referenceLog?.rejectedByUser?.updated_at).date,
                "Approved/Disapproved/Cancelled by": Data?.referenceLog?.approvedByUser
                    ? Data?.referenceLog?.approvedByUser?.email
                    : Data?.referenceLog?.cancelledByUser?.email,
                "Approved/Disapproved/Canceled Receiving time": Data?.referenceLog?.updatedAt,
                "Due Date": Data?.dueDate,
            }

            dataToExport.push(thisObject)

        })

        const csvData = objectToCSV(dataToExport);
        ExportToCSVFile(csvData, "incoming-references.csv");

        this.props.ShowToast("Incoming References exported successfully! Open downloads folder to preview...", "information");

        //Restore the former value...
        this.setState({ perPage: previousPerPage });
        this.getIncomingReferences();

    }

    getIndividualReference = slug => {
        //Fetch the reference
        //According to the
        //Selected slug
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(response => response.json())
            .then(response => {
                if (response && response.ok) {
                    //this.props.setViewData(this.props.item)
                    this.setState({
                        exportData: response.body.data,
                    })
                }
            })

    }

    getIncomingReferences = () => {
        /**
         * This contains
         * the API endpoint
         * for fetching
         * the outgoing
         * references for
         * any particular
         * institution
         */

        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(`${ApiPath}api/e-reference/view-incoming-e-reference?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        metaData: response.body.references.meta,
                        apiData: response.body.references.data,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: [],
                    }, () => {
                        const pagesArray = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagesArray.push({ "id": i, "name": "Item " + i, "each": i + " name" });
                            }

                            this.setState({ arrayPages: pagesArray })
                        })
                    })
                }
            })
    }

    toggleSearchGroup = () => {
        this.setState({
            searchGroupState: !this.state.searchGroupState,
        })
    }

    filterRecords = () => {
        const txtSearch = document.getElementById("txtSearch");
        this.setState({
            searchValue: txtSearch?.value,
        })
        this.getIncomingReferences();
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchTitle: e?.target.value,
            searchField: e?.target.id,
        }, () => {
            this.toggleSearchGroup()
        })
    }

    RefreshForm() {
        this.getIncomingReferences()
    }

    componentDidMount() {
        this.getIncomingReferences()
    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<IncomingReferencesTableRow
                key={counter}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                item={item}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                setDisplayEdit={value => this.setState({ displayEdit: value })}
                setViewData={value => this.setState({ viewData: value })}
                setEditData={value => this.setState({ editData: value })}
                setDisplayView={value => this.setState({ displayView: value })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getIncomingReferences()
            this.setState({
                actionSuccessful: false,
            })
        }

        const navBtns = this.state.arrayPages && this.state.arrayPages.map(item => {
            const active = this.state.currentSelectedPage === item.id ? "active" : "";

            return (
                <button
                    key={item.id}
                    className={active}
                    onClick={() => this.handleSelectedPage(item.id)}
                >{item.id}
                </button>
            )
        })

        const searchGroupClass = this.state.searchGroupState ? "search-group active" : "search-group";
        const searchUserPlaceholder = "Filter by " + this.state.searchTitle

        return (
            <>
                <div className="title">
                    <h3>Incoming References Table</h3>
                    <SearchComponent
                        searchValue={this.state.searchValue}
                        searchTitle={this.state.searchTitle}
                        searchField={this.state.searchField}
                        handleChange={this.handleChange}
                        toggleSearchGroup={this.toggleSearchGroup}
                        searchUserPlaceholder={searchUserPlaceholder}
                        searchGroupClass={searchGroupClass}
                        ReferenceSearchList={ReferenceSearchList}
                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                        filterRecords={this.filterRecords}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            className="btn-grey t-tip mr-1"
                            type="button"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            className="btn-green t-tip"
                            id="btnExport"
                            type="button"
                            data-title="Export to MsExcel/CSV file"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table" id="outgoing_ref_table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">REFERENCE NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NAME</th>
                                <th scope="col" className="align-left">REF. INSTITUTION</th>
                                <th scope="col" className="align-left">REF. BRANCH</th>
                                <th scope="col" className="align-left">REF. A/C NO</th>
                                <th scope="col" className="align-left">REF. A/C NAME</th>
                                <th scope="col" className="align-center">OVERDUE?</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center" width="300">ACTION</th>
                                <th scope="col" className="align-center" width="100">REASSIGN</th>

                            </tr>
                        </thead>
                        <tbody id="tbl_incoming_references">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <PageChanger
                    perPage={this.state.perPage}
                    handleItemsCount={this.handleItemsCount}
                />
                <div className="pagination">
                    <button className="pag" onClick={() => this.handleSelectedPage(1)}><i className="bx bx-chevrons-left"></i></button>
                    {navBtns}
                    <button className="pag1" onClick={() => this.handleSelectedPage(this.state.lastPage)}><i className="bx bx-chevrons-right"></i></button>
                </div>
                {
                    this.state.displayView && <ViewReferenceDialog
                        data={this.state.viewData}
                        displayView={this.state.displayView}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                        setDisplayView={value => this.setState({ displayView: value })}
                        sender={"Incoming Reference"}
                    />
                }

            </>
        )
    }
}

export default IncomingReferenceForm;
