import React from "react"
import ApproveOutgoingReference from "./approve-outgoing-reference"
import ApiPath from '../../../admin/assets/App/api-path'
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../../admin/assets/App/Badges"

class OutgoingReferencesTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            data: [],
            displayViewDialog: false,
            displayApproveDialog: false,
            itemSlug: "",
        }

        this.ViewItem = this.ViewItem.bind(this)
        this.EditItem = this.EditItem.bind(this)
        this.ApproveItem = this.ApproveItem.bind(this)
        this.DeleteItem = this.DeleteItem.bind(this)
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                data: this.props.item,
                itemSlug: this.props.item.slug,
            })
        }
    }

    EditItem() {
        this.props.setEditData(this.props.item);
        this.props.setDisplayEdit(true);
    }

    ViewItem() {
        //Display the dialog
        const slug = this.props.item.slug;
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(response => response.json())
            .then(response => {
                if (response && response.ok) {
                    //this.props.setViewData(this.props.item)
                    this.props.setViewData(response.body.data);
                    this.props.setDisplayView(true);
                }
            })

    }

    ApproveItem() {

        /**
         * Approving an item
         * will entail
         * selecting
         * the proper item
         * and then
         * validating the
         * action, to avoid
         * accidental deletes...
         */

        this.setState({
            displayApproveDialog: true,
            itemSlug: this.props.item.slug,
        })

    }

    DeleteItem() {
        /**
         * Delete the selected
         * item from the list
         * of created references...
         */

        const consent = window.confirm("Do you really wish to delete the selected Reference?");

        if (consent) {
            this.props.setIsLoaderVisible(true);

            const data = { "slug": this.props.item?.slug }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
                body: JSON.stringify(data),
            }

            const endpoint = "api/e-reference/cancel-outgoing-e-reference"
            fetch(ApiPath + endpoint, options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast("Reference Deleted!", "success")
                            this.props.setActionSuccessful(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value(s)", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })

        }

    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const hideButtons = this.props.item?.actionStatus === "cancelled" ? "align-center d-none" : "align-center"

        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = (new Date(this.props.item.dueDate).getTime() < new Date().getTime()) ? "Yes" : "No";
        let displayApproval = "btn-green mr-1";
        let displayEdit = "btn-grey mr-1";
        let deleteButtonClass = "btn-danger";

        if (this.props.item?.stageStatus === "receiving") {
            displayApproval = "btn-green mr-1 d-none";
            displayEdit = "btn-grey mr-1 d-none";
            deleteButtonClass = "btn-danger d-none";

        } else {
            //If the actionStatus has
            //proceeded beyond pending, then
            //Hide the Edit Button...
            if (actionStatus !== "Pending") {
                displayEdit = "btn-grey mr-1 d-none";
                deleteButtonClass = "btn-danger d-none";
            }
        }

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 10)}...</td>
                    <td>{this.props.item?.customerAccountNumber}</td>
                    <td>{this.props.item?.customerAccountName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td className={hideButtons} width="300">
                        <button type="button"
                            id="btnView"
                            className="btn-grey mr-1"
                            onClick={() => this.ViewItem()}>Details
                        </button>
                        <button
                            type="button"
                            id="btnEdit"
                            className={displayEdit}
                            onClick={() => this.EditItem()}>
                            Edit
                        </button>
                        <button
                            type="button"
                            id="btnApprove"
                            className={displayApproval}
                            onClick={() => this.ApproveItem()}>
                            Approve
                        </button>
                        <button
                            type="button"
                            id="btnDelete"
                            className={deleteButtonClass}
                            onClick={() => this.DeleteItem()}
                        >Delete</button>
                    </td>
                    <td className="align-center">
                        <button type="button"
                            id="btnCancel"
                            className="btn-grey"
                        ><i className="bx bx-recycle"></i> Cancel</button>
                    </td>

                </tr>

                {
                    this.state.displayApproveDialog &&
                    <ApproveOutgoingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayApproveDialog={this.state.displayApproveDialog}
                        setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setApproveSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
            </>
        );
    }
}

export default OutgoingReferencesTableRow
