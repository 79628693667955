import NavBar from "../../../admin/assets/App/nav-bar"
import TopBar from "../../../admin/assets/App/topbar"
import LockedScreen from "../../../admin/assets/App/LockedScreen";
import IncomingReferenceForm from "./incoming-reference-form";

import { useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../../admin/assets/App/footer";

export const IncomingReferences = (props) => {
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>E-Reference/Incoming References | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Incoming e-References" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-arrow-left"></i>Incoming E-References</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <IncomingReferenceForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />

                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    )
}
