import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { Enums } from "../../_common/enums";

class ChangePasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {
      txtPassword: "",
      txtNewPassword: "",
      txtVerifyPassword: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick() {
    //Input API for update password here, as soon as Stephen supplies it
    const pwd = document?.getElementById("txtPassword");
    const newPwd = document?.getElementById("txtNewPassword");
    const verifyPwd = document?.getElementById("txtVerifyPassword");

    if (this.state.txtPassword === "") {
      this.props.ShowToast(
        "Enter former password in the shown field",
        "exclamation"
      );
      pwd?.classList.add(Enums.errorBorder);
      pwd?.focus();
      return;
    } else if (this.state.txtNewPassword === "") {
      this.props.ShowToast(
        "Enter new password in the shown field",
        "exclamation"
      );
      newPwd?.classList.add(Enums.errorBorder);
      newPwd?.focus();
      return;
    } else if (this.state.txtVerifyPassword === "") {
      this.props.ShowToast(
        "Enter matching password in the verify password field",
        "exclamation"
      );
      verifyPwd?.classList.add(Enums.errorBorder);
      verifyPwd?.focus();
      return;
    }

    //Verify also if the passwords match
    if (this.state.txtNewPassword !== this.state.txtVerifyPassword) {
      this.props.ShowToast(
        "Your New Password and Re-Enter New Password entries must match!",
        "exclamation"
      );
      verifyPwd?.classList.add(Enums.errorBorder);
      verifyPwd?.focus();
      return;
    }

    try {
      this.props.setIsLoaderVisible(true);

      const data = {
        old_password: this.state.txtPassword,
        new_password: this.state.txtNewPassword,
      };
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": Enums.applicationJson,
          Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
        },
        body: JSON.stringify(data),
      };

      fetch(ApiPath + "api/user/change-password", options)
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          return response.json();
        })
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          if (response) {
            if (response.ok === true) {
              //Password update success
              this.props.ShowToast("Password update successful!");
              this.setState({
                txtPassword: "",
                txtNewPassword: "",
                txtVerifyPassword: "",
              });
            } else {
              this.props.ShowToast(response.message, "exclamation");
              return;
            }
          } else {
            this.props.ShowToast("Invalid login credentials", "exclamation");
          }
        })
        .catch((error) => {
          this.props.ShowToast(error, "exclamation");
        });
    } catch (error) {
      this.props.setIsLoaderVisible(false);
      this.props.ShowToast(error, "exclamation");
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    document?.getElementById([event.target.name])?.classList.remove(Enums.errorBorder);
  }

  render() {
    return (
      <div>
        <div className="form-row-4 mb-2">
          <div className="input-group">
            <label>Enter Former Password</label>
            <input
              type="password"
              id="txtPassword"
              name="txtPassword"
              className="form-control"
              onChange={this.handleChange}
              value={this.state.txtPassword}
            />
            <label className="control-info">
              <i className="icofont-warning-alt"></i> Enter Old Password
            </label>
          </div>
        </div>
        <div className="form-row-4 mb-2">
          <div className="input-group">
            <label>New Password</label>
            <input
              type="password"
              id="txtNewPassword"
              name="txtNewPassword"
              className="form-control"
              onChange={this.handleChange}
              value={this.state.txtNewPassword}
            />
            <label className="control-info">
              <i className="icofont-warning-alt"></i> Enter the New Password
            </label>
          </div>
        </div>
        <div className="form-row-4 mb-2">
          <div className="input-group">
            <label>Re-enter New Password</label>
            <input
              type="password"
              id="txtVerifyPassword"
              name="txtVerifyPassword"
              className="form-control"
              onChange={this.handleChange}
              value={this.state.txtVerifyPassword}
            />
            <label className="control-info">
              <i className="icofont-warning-alt"></i> Re-enter the New Password
            </label>
          </div>
        </div>
        <hr className="mb-3" />
        <div className="form-row-4 mb-2">
          <div className="input-group"></div>
          <div className="input-group">
            <button
              className="btn-green t-tip"
              id="btnUpdatePassword"
              data-title="Click to update the new password"
              onClick={this.handleClick}
            >
              <i className="icofont-upload-alt"></i> Update Password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordForm;
