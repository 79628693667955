const SplitDate = (longDate) => {
    const dateParts = longDate ? longDate?.toString().split("T") : ["-", "-"];

    return ({
        "date": dateParts[0],
        "time": dateParts[1].substring(0, 8),
        "dateTime": `${dateParts[0]} ${dateParts[1].substring(0, 8)}`,
    });
}

export default SplitDate;
