import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import SplitDate from '../../admin/assets/App/split-date'

class InstitutionTypesTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            slug: "",
        }

        this.EditInstitutionType = this.EditInstitutionType.bind(this)
        this.DeleteInstitutionType = this.DeleteInstitutionType.bind(this)
    }

    componentDidMount() {
        this.setState({
            slug: this.props.slug,
        })
    }

    EditInstitutionType(value) {
        this.props.EditInstitutionType(value)
    }

    DeleteInstitutionType(value) {
        //Delete
        try {

            const dialog = window.confirm("Are you sure you wish to delete the selected record?")

            if (!dialog) {
                return
            }

            //Else, good to go
            this.props.setIsLoaderVisible(true)

            //Set the data first...
            const data = { "slug": value };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/institution-type/disable-institution-type', options)
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            setTimeout(() => {
                                this.props.ShowToast("Institution Type deleted successfully!", "success")
                            }, 2000)
                            this.props.isDeleteSuccessful(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid inputs", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                    this.props.setIsLoaderVisible(false)
                })

        } catch (err) {
            this.props.ShowToast(err, "exclamation")
            this.props.setIsLoaderVisible(false)

        }
    }

    render() {
        return (
            <tr>
                <td className="align-center">{this.props.counter}.</td>
                <td>{this.props.item.name}</td>
                <td className="align-center">{this.props.item.institution_count}</td>
                <td className="align-center">{this.props.item.service_count}</td>
                <td>{SplitDate(this.props.item.createdAt).dateTime}</td>
                <td>{SplitDate(this.props.item.createdAt).dateTime}</td>
                <td className="align-center">
                    <button type="button" className="btn-grey mr-1" title="Edit" onClick={() => this.EditInstitutionType(this.props.item.slug)}>
                        <i className="icofont-edit"></i> Edit
                    </button>
                    <button type="button" className="btn-danger" title="Delete" onClick={() => this.DeleteInstitutionType(this.props.item.slug)}>
                        <i className="icofont-ui-delete"></i> Delete
                    </button>
                </td>

            </tr>
        )
    }
}

export default InstitutionTypesTableRow
