const ImageDialog = (props) => {

    const imageSource = props.isURL ? props.src : window.URL.createObjectURL(props.src);
    const dialogClass = props.setImageDisplay ? "dialog-background image-dialog fade" : "dialog-background image-dialog"

    const extension = props.src?.name?.split(".")[1];
    let imageContainerDisplay;
    let embedContainer;

    if (extension === "pdf") {
        embedContainer = "embed-container";
        imageContainerDisplay = "image-container d-none";

    } else {
        embedContainer = "embed-container d-none";
        imageContainerDisplay = "image-container";
    }

    const closeMe = () => {
        props.setImageDisplay(false);
    }

    return (
        <div className={dialogClass}>
            <div className={imageContainerDisplay}>
                <div className="image-class">
                    <img id="imageToBeLoaded" alt="" src={imageSource} />
                </div>
            </div>
            <div className={embedContainer}>
                <embed
                    src={imageSource}
                    width="100%"
                    height="95%"
                />
            </div>
            <br />
            <div className="form-row-3-1">
                <div></div>
                <button
                    className="btn-grey"
                    id="btnClose"
                    onClick={closeMe}
                    style={{width:300}}
                >Close</button>
            </div>
        </div>
    );
}

export default ImageDialog;
