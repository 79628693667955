import React from "react"
import ApiPath from "../../../admin/assets/App/api-path"
import SearchList from "../../../admin/assets/App/SearchList"

export default class AcceptRejectIncomingReference extends React.Component {

    constructor() {
        super()
        this.state = {
            rbtnAccept: true,
            rbtnReject: false,
            txtComments: "",
            approveType: "Accept",
            searchField: "rbtnAccept",
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    closeForm = () => {
        this.props.setDisplayAcceptDialog(false)
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchField: e?.target.id,
        }, () => {
            this.setState({
                approveType: (this.state.searchField === "rbtnAccept") ? "Accept" : "Reject"
            })
        })
    }

    acceptRejectReference = () => {

        const data = { "slug": this.props.slug, "comment": this.state.txtComments }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            body: JSON.stringify(data),
        }

        let endpoint = "";

        if (this.state.approveType === "Accept") {
            endpoint = "api/e-reference/accept-incoming-e-reference"
        } else if (this.state.approveType === "Reject") {
            endpoint = "api/e-reference/reject-incoming-e-reference"
        }

        const confirmAction = window.confirm(`Do you wish to proceed to ${this.state.approveType} the selected reference?`);
        if (confirmAction) {

            this.props.setIsLoaderVisible(true);

            fetch(ApiPath + endpoint, options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast(`Reference ${this.state.approveType}d successfully!`, "success")
                            this.props.setDisplayAcceptDialog(false)
                            this.props.setAcceptSuccessful(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value(s)", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })
        }

    }

    render() {
        const dialogClass = this.props.displayAcceptDialog ? "modal fade show" : "modal fade";

        return (
            <div className={dialogClass}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="create"><i className="icofont-tick-mark"></i> Accept / Reject</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-row-3-1">
                                <label className="modal-info"><span>*</span>(Double-click to select item)</label>
                                <div className="input-group">
                                    <SearchList
                                        id="rbtnAccept"
                                        value="Accept"
                                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                                        searchField={this.state.searchField}
                                        itemName="Accept Incoming Reference"

                                    />
                                    <SearchList
                                        id="rbtnReject"
                                        value="Reject"
                                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                                        searchField={this.state.searchField}
                                        itemName="Reject Incoming Reference"
                                    />
                                </div>
                                <div className="input-group" disabled={!this.state.rbtnReject}>
                                    <textarea
                                        id="txtComments"
                                        name="txtComments"
                                        type="text"
                                        rows="4"
                                        className="form-control"
                                        value={this.state.txtComments}
                                        onChange={this.handleChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                id="btnAcceptReference"
                                className="btn-green mr-1"
                                onClick={this.acceptRejectReference}>
                                <i className="icofont-tick-mark"></i> {this.state.approveType}
                            </button>
                            <button
                                id="btnClose"
                                className="btn-grey"
                                onClick={this.closeForm}>
                                <i className="icofont-exit"></i> Cancel
                            </button>

                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
