import SplitDate from "../../admin/assets/App/split-date";

const InstitutionFormRows = ({ counter, item, EditInstitution }) => {

    function handleClick() {
        //Send the entire item to the edit part
        EditInstitution(item)
    }

    return (
        <tr>
            <td className="align-center">{counter}.</td>
            <td>{item.code}</td>
            <td>{item.name}</td>
            <td>{item.institutionType.name}</td>
            <td>{SplitDate(item.createdAt).dateTime}</td>
            <td>{item.billingType}</td>
            <td className="align-center">
                <button className="btn-grey" title="Edit Institution" button="button" onClick={() => handleClick()}>
                    <span className='icofont-edit'></span> Edit
                </button>
            </td>
        </tr>
    );
}

export default InstitutionFormRows;
