
const Pagination = (props) => {

    const handleItemsCount = e => {
        localStorage.setItem("items_per_page", e.target.value)
        props.setPerPage(e.target.value)
        props.setRefreshForm(true)
    }

    const handleSelectedPage = pageNo => {
        props.setCurrentSelectedPage(pageNo)
        props.setRefreshForm(true);
    }

    const navBtn = props.arrayPages && props.arrayPages.map(item => {
        const active = props.currentSelectedPage === item.id ? "active cls" : "cls";

        return (
            <button
                key={item.id}
                onClick={() => handleSelectedPage(item.id)}
                className={active}>{item.id}
            </button>
        )
    })

    return (
        <>
            <div className="input-form-inline">
                <label>Items per page</label>
                <select
                    type="text"
                    id="perPage"
                    name="perPage"
                    value={props.perPage}
                    onChange={handleItemsCount}
                    className="form-control"
                >
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div className="pagination">
                <button
                    className="pag"
                    onClick={() => handleSelectedPage(1)}>
                    <i className="bx bx-chevrons-left"></i>
                </button>
                {navBtn}
                <button
                    className="pag1"
                    onClick={() => handleSelectedPage(props.lastPage)}>
                    <i className="bx bx-chevrons-right"></i>
                </button>
            </div>
        </>
    );
}

export default Pagination;