import React from 'react'
import ImageDialog from './image-dialog'
import ApiPath from '../../../admin/assets/App/api-path'

export default class CreateReferenceDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            cbxUploadDocument: [],
            displayImage: false,
            imageSource: "",
        }

        this.handleClose = this.handleClose.bind(this)
    }

    updateReference = () => {
        /**
         * Once all the
         * displayed values
         * are correct
         * this proceeds to
         * update to the
         * database using the
         * linked api /
         * endpoint..
         */
        try {

            const data = {
                "customerBranch": (sessionStorage.getItem("session_user_type") === "Admin")
                    ? this.props.data.cbxCustomerBranch
                    : sessionStorage.getItem("customer_branch_slug"),           //Set to customerBranchSlug when ready...
                "refereeBranch": this.props.data.cbxRefereeBranchSlug,
                "refereeInstitution": this.props.data.cbxRefereeInstitution,
                "customerAccountNo": this.props.data.txtAccountNumber,
                "customerAccountName": this.props.data.txtAccountName,
                "customerAccountType": this.props.data.cbxAccountType,
                "refereeAccountName": this.props.data.txtRefereeAccountName,
                "refereeAccountNo": this.props.data.txtRefereeAccountNumber,
                "channel": this.props.data.channel,
                "customerMandate": this.props.data.customerMandate,
                "mandateReference": this.props.data.referenceObject,
                "referenceObject": this.props.data.referenceObject

            }

            const options = {
                body: JSON.stringify(data),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
            }

            //Try to update...
            this.props.setIsLoaderVisible(true);

            fetch(ApiPath + 'api/e-reference/create-e-reference', options)
                ?.then(response => {
                    this.props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {

                    if (response) {
                        if (response.ok === true) {
                            this.props.ShowToast("Create Reference successful!", "success")
                            this.props.setDisplayDialog(false)
                            this.props.setClearData(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                        }
                    } else {
                        this.props.ShowToast("Invalid input variables.", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                })

        } catch (err) {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(err, "exclamation")
        }
    }

    displayImageDialog = () => {
        /**
         * This subroutine
         * is tasked with the sole
         * responsibility of
         * displaying the
         * actual
         * file
         * attached to
         * the form...
         */
        this.setState({
            imageSource: this.state.cbxUploadDocument,
            displayImage: true,
        })

    }

    handleClose() {
        this.props.setDisplayDialog(false);
    }

    render() {
        const dialogClass = (this.props.displayDialogClicked) ? "dialog-background fade" : "dialog-background"

        return (
            <>
                <div className={dialogClass}>
                    <div className="dialog-container" style={{ "background": "#fff" }}>
                        <div className="dialog-header">
                            <h2 className="create"><i className="icofont-letter"></i> Create Reference</h2>
                        </div>
                        <div className="dialog-body">
                            <h4 className="mb-2" style={{ fontSize: 13, fontWeight: "normal" }}>Below are your inputs:</h4>
                            <table className="dialog-table"><caption></caption>
                                <thead>
                                    <tr>
                                        <th scope="col" className="align-left">Field</th>
                                        <th scope="col" className="align-left">Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Customer Account Number</td>
                                        <td id="customerAccountNumber">{this.props.data?.txtAccountNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Account Type</td>
                                        <td id="customerAccountType">{this.props.data?.cbxAccountType}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer Account Name</td>
                                        <td id="customerAccountName">{this.props.data?.txtAccountName}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer Institution</td>
                                        <td id="customerInstitution">{sessionStorage.getItem("institution")}</td>
                                    </tr>
                                    <tr>
                                        <td>Referee's Institution</td>
                                        <td id="refereeInstitution">{this.props.data?.cbxRefereeInstitutionName}</td>
                                    </tr>
                                    <tr>
                                        <td>Referee's Branch</td>
                                        <td id="refereeBranch">{this.props.data?.cbxRefereeBranch || "Central"}</td>
                                    </tr>
                                    <tr>
                                        <td>Referee's Account Number</td>
                                        <td id="refereeAccountNumber">{this.props.data?.txtRefereeAccountNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Referee's Account Name</td>
                                        <td id="refereeAccountName">{this.props.data?.txtRefereeAccountName}</td>
                                    </tr>
                                    <tr>
                                        <td>Referee's Account Type</td>
                                        <td id="refereeAccountType">{this.props.data?.txtRefereeAccountType}</td>
                                    </tr>
                                    <tr>
                                        <td>Attached Reference Form</td>
                                        <td>{this.props.data?.cbxUploadDocument?.name}<br />
                                            <button
                                                type="button" id="btnAttachment"
                                                onClick={this.displayImageDialog} className="btn-grey">
                                                Preview Attachment
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr className="golden-rule" />
                        </div>
                        <div className="dialog-footer">
                            <button
                                className="btn-green mr-1" id="btnUpdateReference"
                                name="btnUpdateReference" type="button"
                                onClick={this.updateReference}
                            ><i className="icofont-upload-alt"></i> Create Reference</button>
                            <button id="btnCancel"
                                type="button" className="btn-grey mr-2"
                                onClick={this.handleClose} >
                                <i className="icofont-exit"></i> Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
                {
                    this.state.displayImage &&
                    <ImageDialog
                        isURL={false}
                        src={this.props.data?.cbxUploadDocument}
                        setImageDisplay={value => this.setState({ displayImage: value })}
                    />
                }

            </>
        )
    }
}
