import React from "react"
import CreateBillingsDialog from "./create-billings-dialog";

export default class BillingsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            viewData: [],
            editData: [],
            fetchSuccessful: false,
            displayCreateDialog: false,
            displayEdit: false,
        }
    }

    fetchBillingsData = () => {
        /**
         * Get the data to be displayed on the form
         */
        this.props.ShowToast("Fetches the Billing data", "success");

    }

    ExportData = () => {
        /**
         * Export the data
         * to an MSExcel
         * file after
         * filtering and
         * saving in a pre-defined
         * format... Consult the
         * requirements document(s)
         */
        this.props.ShowToast("Exports the form in a pre-defined format", "information");

    }

    RefreshForm = () => {
        this.fetchBillingsData();
    }

    render() {
        return (
            <>
                <div className="title">
                    <h3>Billing Page</h3>
                    <div className="buttons">
                        <div className="form-inline">
                            <div className="form-group">

                            </div>
                        </div>
                        <button
                            id="btnCreate"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create Scheduled Billing"
                            onClick={() => this.setState({ displayCreateDialog: true })}>
                            <i className="bx bxs-receipt"></i> Generate Billing...
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportData}>
                            <i className="icofont-download"></i> Download
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">CREATION DATE</th>
                                <th scope="col" className="align-left">END DATE</th>
                                <th scope="col" className="align-left">DAYS</th>
                                <th scope="col" className="align-left">SERVICE</th>
                                <th scope="col" className="align-left">VOLUME</th>
                                <th scope="col" className="align-left">DOWNLOADS</th>
                                <th scope="col" className="align-left">VIEW MORE</th>
                                <th scope="col" className="align-center">SEND TO EMAIL</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_billings">

                        </tbody>
                    </table>
                </div>

                {this.state.displayCreateDialog && <CreateBillingsDialog
                    displayCreateDialog={this.state.displayCreateDialog}
                    setDisplayCreateDialog={value => this.setState({ displayCreateDialog: value })}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setCreateSuccessful={val => this.setState({ actionSuccessful: val })}

                />}

            </>
        )
    }

}

