import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

/**
 * This is the main module
 * that renders
 * the whole app
 * to the index.js page
/**
 * ReactDOM.render() controls the contents of the
 * container node you pass in.
 * Any existing DOM elements inside are replaced when first called.
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
//reportWebVitals()
