import ApiPath from '../../admin/assets/App/api-path'
import React from 'react'

import CreateInstitutionsForm from "./CreateInstitutionsForm"
import EditInstitutionsForm from "./edit-institutions-form"
import InstitutionFormRows from './institution-form-rows';
import PageChanger from '../../admin/assets/App/PageChanger';

class InstitutionsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            rowData: [],
            metaData: {},
            editClicked: false,
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }

        this.handleItemsCount = this.handleItemsCount.bind(this)

    }

    handleItemsCount(e) {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getData()
        })
    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => this.getData())
    }

    EditInstitution = itemArray => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        this.setState({
            rowData: itemArray,
            editClicked: true,
        })

    }

    getData() {
        /**
         * Fetch
         * the data
         * for the individual
         * institutions
         */
        this.props.setIsLoaderVisible(true)

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(`${ApiPath}api/institution/fetch-institutions?page=
            ${this.state.currentSelectedPage}&limit=${this.state.perPage}`, options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.data.institutions,
                            metaData: response.body.data.meta,
                            fetchSuccessful: true,
                            createSuccessful: false,
                            arrayPages: [],

                        }, () => {
                            const arrayPages = [];

                            this.setState({
                                lastPage: this.state.metaData?.last_page,
                                currentSelectedPage: this.state.metaData?.current_page,

                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrayPages.push({ "id": i, "name": "Item " + i });
                                }

                                this.setState({
                                    arrayPages: arrayPages,
                                })
                            })

                        })

                        //Set other parameters
                    } else {
                        this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        /**
         * This ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */
        if (this.state.createSuccessful) {
            this.getData();
            this.setState({
                createSuccessful: false,
            })
        }

        const testVal = "hi";

        //Load the table rows each time the table refreshes
        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            //This next item ensures that a proper counter is established
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (
                <InstitutionFormRows
                    key={item.slug}
                    item={item}
                    counter={itemCounter}
                    EditInstitution={this.EditInstitution}
                />
            )
        })

        const navButtons = this.state.arrayPages && this.state.arrayPages.map(item => {
            const active = this.state.currentSelectedPage === item.id ? "active" : "";

            return (
                <button
                    key={item.id}
                    onClick={() => this.handleSelectedPage(item.id)}
                    className={active}>{item.id}
                </button>
            )
        })

        return (
            <>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Institution Code</th>
                                <th scope="col" className="align-left">Institution Name</th>
                                <th scope="col" className="align-left">Institution Type</th>
                                <th scope="col" className="align-left">Date Created</th>
                                <th scope="col" className="align-left">Billing Type</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_institutions">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <PageChanger
                    perPage={this.state.perPage}
                    handleItemsCount={this.handleItemsCount}
                />
                <button type="button" className="btn-grey d-none" onClick={this.handleItemsCount}><span className="icofont-tick-mark"></span></button>
                <button id="testButton" onChange={() => this.EditInstitution(testVal)} className="d-none"
                    onClick={e => {
                        this.getData();
                        this.handleItemsCount(e);
                        this.handleSelectedPage(1)
                    }}>
                </button>
                <div className="pagination">
                    <button onClick={() => this.handleSelectedPage(1)}><i className="bx bx-chevrons-left"></i></button>
                    {navButtons}
                    <button onClick={() => this.handleSelectedPage(this.state.lastPage)}><i className="bx bx-chevrons-right"></i></button>
                </div>

                <CreateInstitutionsForm
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}

                />

                {this.state.editClicked && <EditInstitutionsForm
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}
            </>
        )
    }
}
export default InstitutionsForm;
