const UsersSearchList = [
    {
        id: 1,
        idVal: "branch",        //This is the searchField in the database
        value: "Branch Name",
        itemName: "Branch Name",
    },
    {
        id: 2,
        idVal: "status",
        value: "Status",
        itemName: "Status"
    },
    {
        id: 3,
        idVal: "email",
        value: "Contact Email",
        itemName: "Contact Email",
    },
    {
        id: 4,
        idVal: "firstName",
        value: "Name",
        itemName: "Name",
    },
    {
        id: 5,
        idVal: "phoneNo",
        value: "Phone Number",
        itemName: "Phone Number",
    },
]

export default UsersSearchList
