import React from 'react'
class ViewUserRoleDialog extends React.Component {
    constructor() {
        super()
        this.state = {
            rowValues: [],
        }
    }

    closeForm() {
        document?.querySelector(".view-services")?.classList.remove("fade")
    }

    render() {
        return (
            <div className="dialog-background view-services">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2><i className="icofont-user-alt-5"></i> User Roles Services</h2>
                    </div>
                    <div className="dialog-body">

                        <table><caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-left">#</th>
                                    <th scope="col" className="align-left">Service Name</th>
                                    <th scope="col" className="align-left">Service Role</th>
                                </tr>
                            </thead>
                            <tbody id="tbl_view_services">

                            </tbody>
                        </table>

                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit">
                            </i> Close
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewUserRoleDialog
