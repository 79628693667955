import NavBar from "../../admin/assets/App/nav-bar";
import TopBar from "../../admin/assets/App/topbar";
import LockedScreen from "../../admin/assets/App/LockedScreen";
import UserManagementForm from "./UserManagementForm";

import { Helmet } from "react-helmet";
import React, { useState } from "react";
import Footer from "../../admin/assets/App/footer";

export const UserManagement = (props) => {

  const [mainClass, setMainClass] = useState("main");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Management | NIBSS Document Management Hub Portal</title>
      </Helmet>
      <LockedScreen
        setIsLoaderVisible={props.setIsLoaderVisible}
        ShowToast={props.ShowToast}
      />
      <div className="cont-ainer">
        <NavBar sender="Users Management" />
        <div className={mainClass}>
          <TopBar
            setMainClass={value => setMainClass(value)}
          />

          <div className="page-title">
            <h2>
              <i className="icofont-mr icofont-users"></i>User Management
            </h2>
          </div>
          <div className="branch-summaries">
            <div className="summaries">
              <UserManagementForm
                ShowToast={props.ShowToast}
                setIsLoaderVisible={props.setIsLoaderVisible}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
