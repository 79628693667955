import React from "react"
import ApiPath from "../../admin/assets/App/api-path";

class EditNIBSSUserDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            txtEmail: "",
            txtUsername: "",
            txtLastname: "",
            txtFirstname: "",
            cbxNibssUserType: "",
            userTypes: [],
            slug: "",
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleUpdateNibssUser = this.handleUpdateNibssUser.bind(this)
        this.closeForm = this.closeForm.bind(this)

    }

    componentDidMount() {
        this.fetchNIBSSUserRoles()

        if (this.props.rowData) {
            this.setState({
                txtEmail: this.props.rowData.email,
                txtUsername: this.props.rowData.username,
                txtLastname: this.props.rowData.lastName,
                txtFirstname: this.props.rowData.firstName,
                cbxNibssUserType: this.props.rowData.userRole.slug,
                slug: this.props.rowData.slug,
            })
        }
    }

    handleChange(e) {
        const { name, value, type, checked } = e.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleUpdateNibssUser() {
        try {
            if (this.state.cbxNibssUserType === "") {
                this.props.ShowToast("Select a valid NIBSS User type before proceeding.", "exclamation")
                return;
            } else if (this.state.txtEmail.indexOf("@") === -1) {
                this.props.ShowToast("Enter a valid email before proceeding", "exclamation");
                return;
            }

            ///Else, proceed...
            this.props.setIsLoaderVisible(true);
            const data = {
                "userRole": this.state.cbxNibssUserType,
                "email": this.state.txtEmail,
                "firstName": this.state.txtFirstname,
                "lastName": this.state.txtLastname,
                "username": this.state.txtUsername,
                "slug": this.state.slug,

            };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/nibbs-user/update-nibbs-user', options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.props.ShowToast("NIBSS User updated successfully!", "success")
                        this.props.setIsCreateSuccessful(true)
                        this.props.setEditClicked(false)
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
        } catch (error) {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(error, "exclamation")
        }
    }

    fetchNIBSSUserRoles() {
        this.props.setIsLoaderVisible(true)

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(ApiPath + 'api/nibbs-user/fetch-nibbs-user-roles', options)
        .then(response => {
            return response.json();
        })
        .then(response => {
            this.props.setIsLoaderVisible(false)
            if (response) {
                if (response.ok === true) {
                    this.setState({ userTypes: response.body.nibbsUserRoles })
                } else {
                    this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                    return;
                }
            } else {
                this.props.ShowToast("Invalid login credentials", "exclamation")
            }
        })
        .catch(error => {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(error.message, "exclamation")
        })
    }

    closeForm() {
        this.props.setEditClicked(false)
    }

    render() {
        const displayClass = (this.props.editClicked) ? "dialog-background edit-nibss-user fade" : "dialog-background edit-nibss-user"
        return (
            <div className={ displayClass }>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="edit"><i className="icofont-users"></i> Edit NIBSS User</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">User Details</h3>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label>NIBSS User Type</label>
                                <select
                                    id="cbxNibssUserType"
                                    name="cbxNibssUserType"
                                    className="custom-select"
                                    value={this.state.cbxNibssUserType}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    {
                                        this.state.userTypes.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label>Enter Email</label>
                                <input type="email" name="txtEmail" className="form-control" onChange={this.handleChange} value={this.state.txtEmail} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label>Firstname</label>
                                <input type="text" name="txtFirstname" className="form-control" onChange={this.handleChange} value={this.state.txtFirstname} required />
                            </div>
                            <div className="input-group col-md">
                                <label>Lastname</label>
                                <input type="text" name="txtLastname" className="form-control" onChange={this.handleChange} value={this.state.txtLastname} required />
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="input-group col-md">
                                <label>Choose Username</label>
                                <input type="text" name="txtUsername" className="form-control" onChange={this.handleChange} value={this.state.txtUsername} required />
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" id="btnUpdateInstitution" name="btnUpdateInstitution" type="button" onClick={this.handleUpdateNibssUser} title="Create">
                            <i className="icofont-upload-alt"></i> Update User
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit" title="Cancel">
                        </i> Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditNIBSSUserDialog;
