const ReferenceSearchList = [
    {
        id: 1,
        idVal: "referenceCode",
        value: "Reference No",
        itemName: "Reference No"
    },
    {
        id: 2,
        idVal: "customerAccountName",
        value: "Cust Acct Name",
        itemName: "Customer Acct Name",
    },
    {
        id: 3,
        idVal: "customerAccountNo",
        value: "Cust Acct No",
        itemName: "Customer Acct Number"
    },
    {
        id: 4,
        idVal: "refereeAccountName",
        value: "Ref Acct Name",
        itemName: "Referee Acct Name",
    },
    {
        id: 5,
        idVal: "refereeAccountNo",
        value: "Ref Acct No",
        itemName: "Referee Acct Number",
    }
]

export default ReferenceSearchList
