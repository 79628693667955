const Badges = (inputSelector) => {

    let output = "";

    switch (inputSelector) {
        case "cancelled":
            output = "badge-cancel align-center"
            break;
        case "approved":
            output = "badge-approved align-center"
            break;
        case "rejected":
            output = "badge-danger align-center"
            break;
        case "pending":
            output = "badge-warning align-center"
            break;
        case "disapproved":
            output = "badge-disapproved align-center"
            break;
        default:
            output = "badge-success align-center"
            break;
    }

    return ( output );
}

export default Badges;
