import React from "react"
import ApiPath from "../../admin/assets/App/api-path";
import ValidateEmail from "../../admin/assets/App/validate-email";
class CreateInstitutionUserDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            cbxInstitution: "",
            cbxBranch: "",
            cbxUserRole: "",
            txtEmail: "",
            txtLastname: "",
            txtFirstname: "",
            txtMiddlename: "",
            txtPhone: "",
            cbxUserType: "",
            institutionsData: [],
            branchData: [],
            filteredBranchData: [],
            userRoleData: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this?.setState({ [name]: value }, () => {
            if (name === "cbxInstitution") {
                //filter the branches to match the selected institution...
                this.filterDataForBranch();
            }
        })

    }

    filterDataForBranch = () => {
        this.setState({
            filteredBranchData: this.state.branchData.filter(item => item.institution?.slug === this.state.cbxInstitution)
        })
    }

    handleSubmit() {
        try {
            //Validate inputs before actual update
            if (this.state.cbxUserType === "") {
                this.props.ShowToast("Select a valid User type before proceeding.", "exclamation")
                return;
            }
            if (this.state.cbxUserRole === "") {
                this.props.ShowToast("User Role Required.", "exclamation")
                return
            } else if (this.state.cbxInstitution === "") {
                this.props.ShowToast("Institution required.", "exclamation")
                return;
            }

            if (this.state.txtFirstname === "") {
                this.props.ShowToast("First Name required", "exclamation");
                return;
            } else if (!ValidateEmail(this.state.txtEmail)) {
                this.props.ShowToast("Valid Email Address required", "exclamation");
                return;
            } else if (this.state.txtLastname === "") {
                this.props.ShowToast("Last Name required!", "exclamation");
                return;
            }

            ///Else, proceed...
            this.props.setIsLoaderVisible(true);
            const data = {
                "institution": this.state.cbxInstitution,
                "userType": this.state.cbxUserType,
                "branch": this.state.cbxBranch,
                "userRole": this.state.cbxUserRole,
                "email": this.state.txtEmail,
                "firstName": this.state.txtFirstname,
                "lastName": this.state.txtLastname,
                "middleName": this.state.txtMiddlename,
                "phoneNo": this.state.txtPhone,

            };

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                },
                body: JSON.stringify(data),
            }

            const userApi = (this.state.cbxUserType === "Institution Admin") ? "api/user/create-institution-admin" : "api/user/create-institution-user";

            fetch(ApiPath + userApi, options)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            /**
                             * Successfuly
                             * created a
                             * new record
                             * proceed to
                             * set props and show
                             * toast
                             */
                            this.props.setIsCreateSuccessful(true)
                            this.props.ShowToast("Institution User created successfully!", "success")
                            document?.querySelector(".create-institution-user")?.classList.remove("fade");
                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value(s)", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                    this.props.setIsLoaderVisible(false)
                })

        } catch (error) {
            this.props.ShowToast(error, "exclamation")
            this.props.setIsLoaderVisible(false)
        }
    }

    closeForm() {
        document?.querySelector(".create-institution-user")?.classList.remove("fade");
    }

    componentDidMount() {
        this.getBranches();

        if (!this.props.isNibssUser) {
            this.setState({
                cbxInstitution: this.props.selectedInstitution,
            })
        }
    }

    getBranches() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(ApiPath + 'api/branch/fetch-branches', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok === true) {
                    this.setState({ branchData: response.body.branches.data })
                }
            })
    }

    render() {
        //Institutions...
        const institutions = this.props.isNibssUser ? this.props.institutionsArray : this.props.institutionsArray?.filter(item =>
            item.slug === sessionStorage.getItem("institution_slug"))

        const InstitutionsArray = institutions?.map(item => {
            return (<option className="bg-white" key={item.slug} value={item.slug}>{item.name}</option>)
        })

        const displayBranch = (this.state.cbxUserType !== "Institution User") ? "input-group d-none" : "input-group"
        const cbxRolesArray = this.props.rolesArray && this.props.rolesArray.map(item => {
            return <option key={item.slug} value={item.slug} className="bg-white">{item.name}</option>
        })

        const selectWithBg = "bg-silver custom-select";
        const selectOnly = "custom-select";

        return (
            <div className="dialog-background create-institution-user">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-users"></i> Create Institution User</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">User Details</h3>

                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution User Type</label>
                                <select
                                    name="cbxUserType"
                                    className={this.state.cbxUserType === "" ? selectWithBg : selectOnly}
                                    value={this.state.cbxUserType}
                                    onChange={this.handleChange}
                                >
                                    <option value="" className="bg-silver">Select User Type</option>
                                    {(this.props.isNibssUser) && <option value="Institution Admin" className="bg-white">Institution Admin</option>}
                                    <option value="Institution User" className="bg-white">Institution User</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>User Role</label>
                                <select
                                    name="cbxUserRole"
                                    value={this.state.cbxUserRole}
                                    onChange={this.handleChange}
                                    className={this.state.cbxUserRole === "" ? selectWithBg : selectOnly}
                                >
                                    <option value="">Select User Role</option>
                                    {cbxRolesArray}
                                </select>
                            </div>
                        </div>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Institution</label>
                                <select
                                    id="cbxInstitution"
                                    name="cbxInstitution"
                                    className={this.state.cbxInstitution === "" ? selectWithBg : selectOnly}
                                    value={this.state.cbxInstitution}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select Institution</option>
                                    {//Return an array of institutions
                                        InstitutionsArray
                                    }
                                </select>
                            </div>
                            <div className={displayBranch}>
                                <label>Branch</label>
                                <select
                                    name="cbxBranch"
                                    className="custom-select"
                                    value={this.state.cbxBranch}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select Branch</option>
                                    {//Return an array of institution Branch, based on the selected institution above.
                                        this.state.filteredBranchData?.map(item => {
                                            return <option key={item.slug} value={item.slug} className="bg-white">{item.branchName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>First Name</label>
                                <input type="text" name="txtFirstname" className="form-control" onChange={this.handleChange} value={this.state.txtFirstname} required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Last Name</label>
                                <input type="text" name="txtLastname" className="form-control" onChange={this.handleChange} value={this.state.txtLastname} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label>Middle Name</label>
                                <input type="text" name="txtMiddlename" className="form-control" onChange={this.handleChange} value={this.state.txtMiddlename} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Email Address</label>
                                <input type="email" name="txtEmail" className="form-control" onChange={this.handleChange} value={this.state.txtEmail} required />
                            </div>
                            <div className="input-group col-md">
                                <label>Phone</label>
                                <input
                                    type="number"
                                    name="txtPhone"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtPhone}
                                    maxLength="11"
                                />
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnUpdateInstitution"
                            name="btnUpdateInstitution"
                            type="button"
                            onClick={this.handleSubmit}
                            title="Create">
                            <i className="icofont-upload-alt"></i> Create Institution User
                        </button>
                        <button
                            type="button"
                            className="btn-grey mr-2"
                            id="btnCancel"
                            onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateInstitutionUserDialog;
