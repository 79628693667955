import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import BillingFeesForm from "./billing-fees-form";

import { useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../admin/assets/App/footer";

export const BillingFees = (props) => {
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Configuration/Billing Fees | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Configure Billing Fees" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-ui-settings"></i>Configuration - Billing Fees</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <BillingFeesForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
