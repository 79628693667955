import React from 'react'
import CreateReferenceDialog from './create-reference-dialog'
import ApiPath from '../../../admin/assets/App/api-path'
import { fileToBase64 } from '../../../admin/assets/App/file-to-base64'

export default class CreateReferenceForm extends React.Component {
    constructor() {
        super()
        this.state = {
            displayDialogClicked: false,
            data: [],
            txtAccountNumber: "",
            cbxAccountType: "",
            txtAccountName: "",
            cbxCustomerBranch: "",
            cbxRefereeInstitution: "",
            cbxRefereeInstitutionName: "",
            cbxRefereeInstitutionSlug: "",
            cbxRefereeBranchName: "Central",
            cbxRefereeBranchSlug: "",
            txtRefereeAccountNumber: "",
            txtRefereeAccountName: "",
            txtRefereeAccountType: "",
            cbxUploadDocument: "",
            referenceObject: "",
            fetchingAccountDetails: false,
            institutionsArray: [],
            branchesArray: [],
            clearData: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    clearAllData() {
        //This more like re-initializes the form,
        //Making it ready for new
        //Inputs after each update..
        this.setState({
            displayDialogClicked: false,
            data: [],
            txtAccountNumber: "",
            cbxAccountType: "",
            txtAccountName: "",
            cbxCustomerBranch: "",
            cbxRefereeInstitution: "",
            cbxRefereeInstitutionName: "",
            cbxRefereeInstitutionSlug: "",
            cbxRefereeBranchName: "Central",
            cbxRefereeBranchSlug: "",
            txtRefereeAccountNumber: "",
            txtRefereeAccountName: "",
            txtRefereeAccountType: "",
            cbxUploadDocument: "",
            fetchingAccountDetails: false,
            institutionsArray: [],
            branchesArray: [],
            clearData: false,
        })
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.institutions.sort(),
                    })
                }
            })
    }

    fetchBranches = () => {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
            },
        }

        fetch(`${ApiPath}api/branch/fetch-branches?institution=${this.state.cbxRefereeInstitution}`, options)
            ?.then(ret => {
                return ret.json();
            })
            .then(res => {
                if (res && res.ok) {
                    this.setState({
                        branchesArray: res.body.branches.data,
                    })
                }
            })
    }

    componentDidMount() {
        this.fetchInstitutions();
        this.fetchBranches();
    }

    fetchAccountDetails = () => {
        /**
         * As the name implies
         * get the account details
         * based on the supplied data...
         */
        if (this.state.txtRefereeAccountNumber === "" || this.state.txtRefereeAccountNumber.length < 10) {
            this.props.ShowToast("Enter a valid 10-digit referee's account number before proceeding", "exclamation");
            return;
        }

        //The usual options
        const options = {
            method: "GET",
        }

        //The actual fetch...
        fetch("https://10.7.7.149:5035/icad/accounts/" + this.state.txtRefereeAccountNumber, options)
            ?.then(response => { return response.json() })
            .then(response => {
                if (response && response.ok) {
                    // this.setState({
                    //     txtRefereeAccountName: response.accountName,
                    //     txtRefereeAccountType: response.body.accountType,
                    // })
                    this.props.ShowToast(response, "success")      //Remove code eventually...
                }
            })

        this.setState({
            fetchingAccountDetails: true,
        })

        //Always set this variable to true at the
        //End of the fetch...
        this.setState({
            fetchingAccountDetails: false,
        })

    }

    getInstitutionName = slugValue => {
        // filter the record to
        // provide the necessary
        const retValue = this.state.institutionsArray.find(inst => inst.slug === slugValue);
        return retValue?.name;
    }

    getBranchName = slugValue => {
        // filter the record to
        // provide the necessary
        const retValue = this.state.branchesArray.find(item => item.slug === slugValue);
        return retValue?.branchName;
    }

    handleChange = e => {
        /**
         * set the state
         * of each parameter
         * according to the
         * value
         * on input.change
         * run...
         */
        if (this.state.displayDialogClicked) {
            return
        }

        this.setState({ [e?.target.name]: e?.target.value }, () => {
            if (e?.target.name === "cbxRefereeInstitution") {
                this.setState({
                    cbxRefereeInstitutionName: this.getInstitutionName(this.state.cbxRefereeInstitution),
                })
            }
            if (e?.target.name === "cbxRefereeBranchSlug") {
                this.setState({
                    cbxRefereeBranchName: this.getBranchName(this.state.cbxRefereeBranchSlug),
                })
            }
        });
    }

    handleBlur(e) {
        /**
         * As the name imples
         * each time
         * the control loses
         * focus, we check
         * to see if the
         * expected conditions
         * are met
         * if they are met, they
         */
        e?.target?.classList.remove("error-border")
    }

    handleKeyUp(e) {
        /**
        * As the name implies
        * each time
        * the use types a
        * letter,
        * it is capitalized
        * and rendered in the
        * screen.
        */
        this.setState({
            txtAccountName: e?.target.value.toUpperCase()
        })
    }

    saveImageToBase64 = () => {
        const fileUpload = document.querySelector("#cbxUploadDocument")
        if (fileUpload?.files[0]) {
            //Ensuring that there is a file to convert
            fileToBase64(fileUpload?.files[0])
                .then(response => {
                    console.log(response);
                    this.setState({
                        referenceObject: response.toString(),
                    })
                    return response.toString();
                });

        } else {
            this.setState({ referenceObject: "" })
        }
    }

    handleDisplayDialog = () => {
        try {
            const container = document.querySelector(".cont-ainer");
            const inputs = container.querySelectorAll(".form-control");
            const fileUpload = document.querySelector("#cbxUploadDocument")
            let issuesCount = 0;

            inputs.forEach(element => {
                if (element.value === "") {
                    element.classList.add("error-border");
                    issuesCount++;
                }
            })

            if (issuesCount > 0) {
                this.props.ShowToast("Complete the missing fields as displayed", "exclamation");
                return
            }

            this.setState({
                displayDialogClicked: true,
                data: {
                    "txtAccountNumber": this.state.txtAccountNumber,
                    "cbxAccountType": this.state.cbxAccountType,
                    "txtAccountName": this.state.txtAccountName,
                    "cbxCustomerBranch": this.state.cbxCustomerBranch,
                    "cbxRefereeInstitution": this.state.cbxRefereeInstitution,
                    "cbxRefereeInstitutionName": this.state.cbxRefereeInstitutionName,
                    "cbxRefereeBranchName": this.state.cbxRefereeBranchName,
                    "cbxRefereeBranchSlug": this.state.cbxRefereeBranchSlug,
                    "txtRefereeAccountNumber": this.state.txtRefereeAccountNumber,
                    "btnGetRefAccountDetails": this.state.btnGetRefAccountDetails,
                    "txtRefereeAccountName": this.state.txtRefereeAccountName,
                    "txtRefereeAccountType": this.state.txtRefereeAccountType,
                    "cbxUploadDocument": fileUpload.files[0],
                    "referenceObject": this.state.referenceObject,
                    "channel": "Other",
                    "customerMandate": "Reference Form",
                }
            })

        } catch (error) {
            this.props.ShowToast(error, "exclamation");
        }
    }

    render() {
        if (this.state.clearData) {
            this.clearAllData()
        }
        const spinnerClass = (this.state.fetchingAccountDetails === true) ? "icofont-spinner" : "icofont-spinner d-none"
        const SetInstitutions = this.state.institutionsArray && this.state.institutionsArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.name}</option>
            )
        })
        const SetBranches = this.state.branchesArray && this.state.branchesArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.branchName}</option>
            )
        })

        return (<>
            <div className="form-row-4 mb-2">
                <div className="input-group">
                    <label><span>*</span>Customer Account Number</label>
                    <input
                        type="text"
                        id="txtAccountNumber"
                        name="txtAccountNumber"
                        value={this.state.txtAccountNumber}
                        className="form-control"
                        maxLength="10"
                        onChange={this.handleChange}
                        placeholder="XXXXXXXXXX"
                        onBlur={this.handleBlur}
                        required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-digits NUBAN Number</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Customer Account Type</label>
                    <select
                        className="form-control"
                        id="cbxAccountType"
                        name="cbxAccountType"
                        value={this.state.cbxAccountType}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        <option value="Individual">Individual</option>
                        <option value="Corporate">Corporate</option>
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Type of Account</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Account Name</label>
                    <input
                        type="text"
                        id="txtAccountName"
                        name="txtAccountName"
                        value={this.state.txtAccountName}
                        className="form-control"
                        onChange={this.handleChange}
                        onKeyUp={this.handleKeyUp}
                        onBlur={this.handleBlur} />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Name on account</label>
                </div>
                <div className={sessionStorage.getItem("session_user_type") === "Admin" ? "input-group" : "input-group d-none"}>
                    <label><span>*</span>Customer Branch</label>
                    <select
                        className="form-control"
                        id="cbxCustomerBranch"
                        name="cbxCustomerBranch"
                        value={this.state.cbxCustomerBranch}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}>
                        <option value=""></option>
                        {SetBranches}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Customer's Branch</label>
                </div>
            </div>
            <div className="form-row-2-1-1 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Institution</label>
                    <select
                        className="form-control" id="cbxRefereeInstitution"
                        name="cbxRefereeInstitution" value={this.state.cbxRefereeInstitution}
                        onChange={this.handleChange} onBlur={this.handleBlur}>
                        <option value=""></option>
                        {SetInstitutions}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution</label>
                </div>
                <div className="input-group">
                    <label>Referee's Branch</label>
                    <select
                        className="form-control" id="cbxRefereeBranch"
                        name="cbxRefereeBranchSlug" value={this.state.cbxRefereeBranchSlug}
                        onChange={this.handleChange} onBlur={this.handleBlur}>
                        <option value="Central">Central</option>
                        {SetBranches}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Branch of Referee (optional)</label>
                </div>
            </div>

            <div className="form-row-4 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Account Number</label>
                    <input
                        type="text" id="txtRefereeAccountNumber"
                        name="txtRefereeAccountNumber" value={this.state.txtRefereeAccountNumber}
                        onChange={this.handleChange} className="form-control"
                        maxLength="10" placeholder="XXXXXXXXXX"
                        onBlur={this.handleBlur} required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-digits NUBAN Number</label>
                </div>
                <div className="input-group">
                    <button
                        className="btn-green mt-2"
                        title="Get Account Details"
                        id="btnGetRefAccountDetails"
                        onClick={this.fetchAccountDetails}>
                        <i className="icofont-info-circle"></i> Get Account Name
                    </button>
                    <button
                        className="d-none"
                        id="testButton"
                        onClick={() => {
                            this.fetchInstitutions()
                            this.fetchAccountDetails()
                        }}
                        onChange={e => {
                            this.handleChange(e)
                            this.handleBlur(e)
                            this.handleKeyUp(e)
                        }}
                    ></button>
                </div>
                <div className="Input-group">
                    <i className={spinnerClass} id="spinner"></i>
                </div>
            </div>
            <div className="form-row-1-1-2 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Account Name</label>
                    <input
                        type="text"
                        id="txtRefereeAccountName"
                        name="txtRefereeAccountName"
                        value={this.state.txtRefereeAccountName}
                        onChange={this.handleChange}
                        className="form-control"
                        onBlur={this.handleBlur}
                        required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Name on Referee's Account</label>
                </div>
                <div className="input-group">
                    <label>Account Type</label>
                    <input
                        type="text"
                        id="txtRefereeAccountType"
                        name="txtRefereeAccountType"
                        onChange={this.handleChange}
                        className="form-control"
                        onBlur={this.handleBlur}
                        value={this.state.txtRefereeAccountType}
                    />
                </div>
                <div className="input-group">
                    <label><span>*</span>Attach Referee Form</label>
                    <input
                        type="file"
                        id="cbxUploadDocument"
                        className="form-control"
                        onBlur={this.handleBlur}
                        onChange={this.saveImageToBase64}
                        accept="image/*,.pdf"
                    />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Choose pdf or image file (.pdf | .jpg | .jpeg | .png)</label>
                </div>
            </div>
            <hr className="mb-3" />
            <div className="form-row-3 mb-2">
                <div className="input-group"></div>
                <div className="input-group">
                    <button
                        className="btn-green t-tip"
                        id="btnCreateReference"
                        data-title="Click to update the reference details"
                        onClick={this.handleDisplayDialog}>
                        <i className="icofont-plus"></i> Create Reference
                    </button>
                </div>
            </div>
            {this.state.displayDialogClicked &&
                <CreateReferenceDialog
                    data={this.state.data}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    displayDialogClicked={this.state.displayDialogClicked}
                    setDisplayDialog={value => this.setState({ displayDialogClicked: value })}
                    setClearData={value => this.setState({ clearData: value })}

                />}

        </>)
    }
}
