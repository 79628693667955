/**
 * This where we define re-usable strings and constants
 * ====
 * Enums are one of the few features TypeScript has which is not a type-level extension of JavaScript.
 * Enums allow a developer to define a set of named constants. Using enums can make it easier
 * to document intent, or create a set of distinct cases. TypeScript provides both numeric and string-based enums.
 */

export const Enums = {
  errorBorder: "error-border",
  userDetails: "user-details",
  txtAddEmail: "txtAddEmail",
  applicationJson: "application/json",
  headersData: function(token) {
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
   }
  },
  accept: "accept",
  reject: "reject",
  approve: "approve",
  disapprove : 'disapprove',
};
