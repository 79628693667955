const navLinksData = [
    {
        id: 1,
        location: "/admin/dashboard",
        iconclass: "icofont-dashboard",
        linkName: "Dashboard",
        link_id: "link_dashboard",
        forAll: false,

    },
    {
        id: 2,
        location: "/admin/audit-logs",
        iconclass: "icofont-data",
        linkName: "Audit Logs",
        lineExists: true,
        link_id: "link_audit_logs",
        forAll: false,

    },
    {
        id: 3,
        location: "/institutions/institutions",
        iconclass: "icofont-institution",
        linkName: "Institution Management",
        lineExists: true,
        link_id: "link_institutions",
        forAll: false,

    },
    {
        id: 4,
        location: "/branch-management/branch-management",
        iconclass: "icofont-chart-flow-2",
        linkName: "Branch Management",
        link_id: "link_branch_management",
        forAll: true,

    },
    {
        id: 5,
        location: "/users/users-management",
        iconclass: "icofont-users",
        linkName: "Users Management",
        lineExists: true,
        link_id: "link_user_management",
        forAll: true,

    },
    {
        id: 6,
        location: "/config/admin-group-emails",
        iconclass: "icofont-email",
        linkName: "Admin Group Emails",
        link_id: "link_admin_emails",
        forAll: false,

    },
    {
        id: 7,
        location: "/config/institution-types",
        iconclass: "icofont-building-alt",
        linkName: "Institution Types",
        link_id: "link_institution_types",
        forAll: false,

    },
    {
        id: 8,
        location: "/config/user-roles",
        iconclass: "icofont-user-alt-5",
        linkName: "User Roles",
        link_id: "link_user_roles",
        forAll: false,

    },
    {
        id: 9,
        location: "/config/public-holidays",
        iconclass: "icofont-ui-calendar",
        linkName: "Public Holidays",
        link_id: "link_public_holidays",
        forAll: false,

    },
    {
        id: 10,
        location: "/config/configure-vat",
        iconclass: "bx bx-money",
        linkName: "Configure VAT",
        link_id: "link_configure_vat",
        forAll: false,

    },
    {
        id: 50,
        location: "/configuration/e-reference-and-billing/billing-fee-settings",
        iconclass: "bx bx-exclude",
        linkName: "Configure Billing Fees",
        link_id: "link_billing_fees",
        forAll: false,

    },
    {
        id: 11,
        location: "/config/change-password",
        iconclass: "bx bx-user-circle",
        linkName: "Change Password",
        lineExists: true,
        link_id: "link_change_password",
        forAll: true,

    },
    {
        id: 12,
        location: "/e-reference/create-reference",
        iconclass: "icofont-letter",
        linkName: "Create e-Reference",
        link_id: "link_create_e_reference",
        forAll: true,

    },
    {
        id: 13,
        location: "/e-reference/outgoing-references",
        iconclass: "icofont-arrow-right",
        linkName: "Outgoing e-References",
        link_id: "link_outgoing_references",
        lineExists: false,
        forAll: true,

    },
    {
        id: 14,
        location: "/e-reference/incoming-references",
        iconclass: "icofont-arrow-left",
        linkName: "Incoming e-References",
        link_id: "link_incoming_references",
        forAll: true,

    },
    {
        id: 15,
        location: "/e-reference/reference-reports",
        iconclass: "bx bxs-book-content",
        linkName: "Reference Reports",
        link_id: "link_reference_reports",
        lineExists: false,
        forAll: true,

    },
    {
        id: 16,
        location: "/e-reference/billing",
        iconclass: "icofont-chart-bar-graph",
        linkName: "Billing & Reports",
        link_id: "link_billing_and_reports",
        lineExists: false,
        forAll: false,

    },
    {
        id: 17,
        location: "/e-reference/billing-and-reports/scheduled-billings",
        iconclass: "bx bx-timer",
        linkName: "Scheduled Billing",
        link_id: "link_billing_and_reports",
        lineExists: true,
        forAll: false,

    },
]

export default navLinksData
