import React from 'react';
import ReferenceReportsTableRow from "./reference-reports-table-row"
import ApiPath from '../../../admin/assets/App/api-path';
import Pagination from '../../../admin/assets/App/Pagination';
import SearchComponent from '../../../admin/assets/App/SearchComponent';
import RefReportSearchList from '../../../admin/assets/data/RefReportSearchList';
import ReferenceReportsSummary from './reference-reports-summary';
import SplitDate from '../../../admin/assets/App/split-date';
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";

export default class ReferenceReportsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            summary: [],
            searchTitle: "Cust Acct Name",
            searchField: "customerAccountName",
            searchValue: "",
            searchGroupState: false,
            fetchSuccessful: false,
            actionSuccessful: false,
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            arrayPages: [],
            lastPage: 1,
            totalItems: 0,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    ExportDataToFile = () => {
        /**
        * Export the data
        * to an MSExcel
        * file after
        * filtering and
        * saving in a pre-defined
        * format... Consult the
        * requirements document(s)
        * We need to obtain the data then do the necessary...
        */
        //always first set the items to 1000, then
        //revert it after the export is done...
        const prevPerPage = this.state.perPage;
        this.setState({ perPage: 20000 }, () => this.getReferences())

        const DataToExport = [];
        this.state.apiData.forEach(item => {
            //First, get individual reference details

            var thisObject = {
                "Reference No": item?.referenceNo,
                "Status": item?.actionStatus,
                "Customer Account": item?.customerAccountNumber,
                "Customer Name": item?.customerAccountName,
                "Customer Bank": sessionStorage.getItem("institution"),
                "Customer Branch": item?.customerBranch?.branchName,
                "Referee Account Name": item?.refereeAccountName,
                "Referee Bank": item?.receiverInstitution?.name,
                "Referee Branch": item?.receiverBranch?.branchName,
                "Referee A/C No": item?.refereeAccountNumber,
                "Created By": item?.createdByUser?.email,
                "Creation Date": SplitDate(item?.createdAt).date,
                "Submitted/Rejected": item?.referenceLog?.submittedByUser ? "Submitted" : "Rejected",
                "Submitted/Rejected Presenting Time": item?.referenceLog?.submittedByUser
                    ? SplitDate(item?.referenceLog?.submittedByUser?.updated_at).date
                    : SplitDate(item?.referenceLog?.rejectedByUser?.updated_at).date,
                "Accepted/Rejected Receiving By": item?.referenceLog?.acceptedByUser
                    ? item?.referenceLog?.acceptedByUser?.email
                    : item?.referenceLog?.rejectedByUser?.email,
                "Accepted/Rejected Receiving Time": item?.referenceLog?.acceptedByUser
                    ? SplitDate(item?.referenceLog?.acceptedByUser?.updated_at).date
                    : SplitDate(item?.referenceLog?.rejectedByUser?.updated_at).date,
                "Approved/Disapproved/Cancelled by": item?.referenceLog?.approvedByUser
                    ? item?.referenceLog?.approvedByUser?.email
                    : item?.referenceLog?.cancelledByUser?.email,
                "Approved/Disapproved/Canceled Receiving time": item?.referenceLog?.updatedAt,
                "Due Date": item?.dueDate,
            }

            DataToExport.push(thisObject)

        })

        const csvData = objectToCSV(DataToExport);
        ExportToCSVFile(csvData, "e-Reference.csv");

        this.props.ShowToast("References exported successfully! Open downloads folder to preview...", "information");

        //Return the number of items per page
        this.setState({ perPage: prevPerPage })
        this.getReferences();


    }

    getReferences = () => {
        /**
         * This contains
         * the API endpoint
         * for fetching
         * the outgoing
         * references for
         * any particular
         * institution
         */

        //Always set the action state
        //button to false
        //after fetching

        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }

        fetch(`${ApiPath}api/e-reference-report/get-e-reference-report?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${this.state.searchValue}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this?.setState({
                            apiData: response.body.data.references.data,
                            summary: response.body.data.summary,
                            fetchSuccessful: true,
                            metaData: response.body.data.references.meta,
                            actionSuccessful: false,
                            arrayPages: [],
                        }, () => {
                            const pagesArr = [];
                            this.setState({
                                currentSelectedPage: this.state.metaData?.current_page,
                                lastPage: this.state.metaData?.last_page,
                                totalItems: this.state.metaData?.total,
                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    pagesArr.push({ "id": i, "name": "Item " + i });
                                }

                                this.setState({ arrayPages: pagesArr })
                            })
                        })
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("No data fetched. Please try again.", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    RefreshForm = () => {
        this.getReferences()
    }

    handleChange(e) {
        this.setState({ searchValue: e?.target?.value })
    }

    componentDidMount() {
        this.getReferences()
    }

    toggleSearchGroup = () => {
        this.setState({
            searchGroupState: !this.state.searchGroupState,
        })
    }

    filterRecords = () => {
        const txtSearch = document.getElementById("txtSearch");
        this.setState({
            searchValue: txtSearch?.value,
        })
        this.getReferences();
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchTitle: e?.target?.value,
            searchField: e?.target?.id,
        }, () => {
            this.toggleSearchGroup()
        })
    }

    render() {

        const isNibssUser = sessionStorage.getItem("is_nibss_user").indexOf("NIBSS") > -1 ? true : false;

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<ReferenceReportsTableRow
                key={itemCounter}
                item={item}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
            />)
        })

        if (this.state.actionSuccessful) {
            this.getReferences()
            this.setState({
                actionSuccessful: false,
            })
        }

        const searchGroupClass = this.state.searchGroupState ? "search-group active" : "search-group";
        const searchUserPlaceholder = "Filter by " + this.state.searchTitle

        return (
            <>
                <div className="title">
                    <h3>Reference Reports Table</h3>
                    <SearchComponent
                        searchGroupClass={searchGroupClass}
                        searchValue={this.state.searchValue}
                        searchTitle={this.state.searchTitle}
                        searchField={this.state.searchField}
                        searchUserPlaceholder={searchUserPlaceholder}
                        toggleSearchGroup={this.toggleSearchGroup}
                        handleChange={this.handleChange}
                        ReferenceSearchList={RefReportSearchList}
                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                        filterRecords={this.filterRecords}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportDataToFile}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container" style={{ overflowX: 'auto' }}>
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" title="">#</th>
                                <th scope="col" title="Reference Number">REF. NO</th>
                                <th scope="col" title="Status" width="100">STATUS</th>
                                <th scope="col" title="Customer Bank">CUST BANK</th>
                                <th scope="col" title="Customer Branch">CUST BRANCH</th>
                                <th scope="col" title="Referee Bank">REF BANK</th>
                                <th scope="col" title="Referee Branch">REF BRANCH</th>
                                <th scope="col" title="Referee Account Name">REF A/C NAME</th>
                                <th scope="col" title="Referee Account Number">REF A/C NO</th>
                                <th scope="col" title="Customer Creation Date">CUST CREATION DATE</th>
                                <th scope="col" title="Referee Creation Date">REF CREATION DATE</th>
                                <th scope="col" title="Created by Presenting">CREATED BY PRESENTING</th>
                                <th scope="col" title="Submitted by Presenting">SUB. BY PRESENTING</th>
                                <th scope="col" title="Accepted/Rejected By Receiving">ACC/REJ BY RECEIVING</th>
                                <th scope="col" title="Approved/Disapproved By Receiving">APP/DIS BY RECEIVING</th>
                                <th scope="col" title="Overdue">OVERDUE</th>
                                <th scope="col" title="Due Date">DUE DATE</th>
                                <th scope="col" title="Document/Attachment">DOC/ATTACH</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_reference_reports">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {!isNibssUser && <ReferenceReportsSummary
                    totalItems={this.state.totalItems}
                    summary={this.state.summary}
                />}
            </>
        );
    }

}
