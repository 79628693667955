import NavHeader from './nav-header'
import NavLinks from './nav-links'
import navLinksData from '../data/nav-links-data'
import { Link } from 'react-router-dom'

const NavBar = ({ sender }) => {

    function LogOut(e) {

        e.preventDefault();

        //Clean out the things needed...
        const userType = sessionStorage.getItem("is_nibss_user")
        sessionStorage.setItem("session_token_timestamp", null);
        sessionStorage.setItem("session_valid_user", null);
        sessionStorage.setItem("session_token", null);
        sessionStorage.setItem("session_locked_state", null);
        sessionStorage.setItem("session_first_name", null);
        sessionStorage.setItem("session_last_name", null);
        sessionStorage.setItem("session_user_type", null);
        sessionStorage.setItem("institution", null);
        sessionStorage.setItem("customer_branch", null);
        sessionStorage.setItem("customer_branch_slug", null);
        sessionStorage.setItem("institution_slug", null);

        //Sign out
        window.location.href = (userType?.indexOf("NIBSS") > -1) ? "/nibss-user-login"  : "/"

    }

    const NavLinkValues = navLinksData.map(item => {
        const linkActive = (sender === item.linkName) ? "nav-active" : ''
        let showNavLink = (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) ? true : item.forAll

        if (item.id > 11 && item.id < 15 && sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) {
            //This makes sure that the
            // e-reference part does not display to the nibss users
            showNavLink = false
        }

        return (showNavLink && <NavLinks
            key={item.id}
            navActive={linkActive}
            iconClass={item.iconclass}
            linkName={item.linkName}
            drawLine={item.lineExists}
            to={item.location}
            linkID={item.link_id}
        />)
    })


    return (
        <div className="cont-ainer">
            <div className="navigation">
                <ul>
                    <NavHeader />
                    { NavLinkValues }

                    <li onClick={LogOut}>
                        <Link to='/' onClick={LogOut}>
                            <i className="icofont-close-circled"></i>
                            <span className="title">Sign Out</span>
                        </Link>
                    </li>
                </ul>
                <button className="d-none" id="testButton" onClick={e => LogOut(e)}></button>
            </div>
        </div>
    )
}

export default NavBar
