import { useState } from 'react';
import ApiPath from './../../admin/assets/App/api-path'
import { useHistory } from 'react-router';
import ValidateEmail from '../../admin/assets/App/validate-email';

const LoginFormData = (props) => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [errorBorderPresent, setIsErrorBorderPresent] = useState(false);

  const history = useHistory();

  function handleChange(e) {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value)
        break;
      case "password":
        setPassword(e.target.value)
        break;
      default:
        break;
    }
  }

  function handleEnterPressed(e) {
    if (e?.keyCode === 13) {
      handleFormSubmit();
    }
  }

  function handleFormSubmit() {
    const msgName = !props.isNibssUser ? "email" : "username";

    if (email === "") {
      props.ShowToast(
        `Enter a valid ${msgName} before proceeding`,
        "exclamation"
      );
      setIsErrorBorderPresent(() => true);
      return;
    }

    if (!props.isNibssUser && !ValidateEmail(email)) {
      props.ShowToast("Valid Email address required!", "exclamation");
      setIsErrorBorderPresent(() => true);
      return;
    }

    if (password === "") {
      props.ShowToast("Password required!", "exclamation");
      setIsErrorBorderPresent(true);
      return;
    }

    const data = props.isNibssUser ? { username: email, password: password } : { email: email, password: password };

    props.setIsLoaderVisible(true); //Show toast

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const loginPath = props.isNibssUser
      ? "api/auth/nibss-login"
      : "api/auth/login";

    fetch(ApiPath + loginPath, options)
      .then((response) => {
        props.setIsLoaderVisible(false);
        return response.json();
      })
      .then((response) => {
        props.setIsLoaderVisible(false);

        if (response && response.ok === true) {
          const output = response.body;
          sessionStorage.setItem("session_valid_user", email);

          if (props.isNibssUser) {
            const $timeStamp = new Date().getTime();
            sessionStorage.setItem("session_token", output.data.bearer_token.token);
            sessionStorage.setItem("session_token_timestamp", $timeStamp);
            sessionStorage.setItem("session_locked_state", "unlocked");
            sessionStorage.setItem("session_timeout_period", 1000 * 60 * 10); //Timeout = 10 mins

            //Data
            sessionStorage.setItem("session_first_name", output.data.firstName);
            sessionStorage.setItem("session_last_name", output.data.lastName);
            sessionStorage.setItem("session_user_type", output.data.userRole);
            sessionStorage.setItem("is_nibss_user", "NIBSS");
            sessionStorage.setItem("toggle_state", "toggle")

            history.push("/admin/dashboard");

          } else {
            history.push("/login-validate");
          }
        } else {
          props.ShowToast(response.message, "exclamation");
        }
      })
      .catch((error) => {
        props.setIsLoaderVisible(false);
        props.ShowToast(error.message, "exclamation");
      });
  }

  return (
    <>
      <div
        className="login-data"
        id="jest-test-id"
        onSubmit={handleEnterPressed}
      >
        <h3>
          <i className="icofont-mr icofont-lock"></i>Secure Login{props.isNibssUser ? " - NIBSS User" : ""}
        </h3>
        <div className="login-details">
          <div className="controls">
            <span className="bx bx-mail-send"></span>
            <input
              type={props.isNibssUser ? "text" : "email"}
              className={errorBorderPresent ? "error-border" : ""}
              id="email"
              name="email"
              placeholder={props.isNibssUser ? "Username" : "Email Address"}
              onChange={handleChange}
              value={email}
              required
            />
            <label className="control-info d-none" id="emailError">
              <span className="icofont-warning-alt"></span> Enter valid and
              registered email
            </label>
          </div>
          <div className="controls">
            <span className="bx bx-key"></span>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              value={password}
              required
            />
            <label className="control-info d-none" id="passwordError">
              <span className="icofont-warning-alt"></span> Enter valid
              password
            </label>
          </div>

          <button type="submit" onClick={handleFormSubmit} id="btnLogin">
            Login
          </button>

          <a href="forgot-password" className="forgot-password">
            Forgot Password?
          </a>
        </div>
        <div className="cant-access">
          <a href="mailto:admin@spexesconsulting.com">
            Can't access your account?
          </a>
        </div>
      </div>
    </>
  );
}

export default LoginFormData
