import React from 'react'
import ApiPath from '../../admin/assets/App/api-path';

class CreateInstitutionsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            txtInstitutionName: "",
            txtInstitutionCode: "",
            cbxInstitutionType: "",
            cbxBillingType: "",
            txtBillingBankCode: "",
            txtBillingAccountNumber: "",
            txtRevenueBankCode: "",
            txtRevenueAccountNumber: "",
            txtAddress: "",
            txtEmail: "",
            chkAllowAPIAccess: false,
            chkAllowERef: false,
            txtNotifyURL: "",
            apiData: [],
        }

        this.handleCreateInstitutionChange = this.handleCreateInstitutionChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleCreateInstitutionSubmit = this.handleCreateInstitutionSubmit.bind(this)
        this.getData = this.getData.bind(this)

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
        }

    }

    getData() {

        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(ApiPath + 'api/institution-type/fetch-institution-type', options)
        ?.then(response => {
            return response.json();
        })
        .then(response => {
            this.props.setIsLoaderVisible(false)
            if (response) {
                if (response.ok === true) {
                    this.setState({ apiData: response.body.institutionTypes })

                } else {
                    this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                    return;
                }

            } else {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast("Invalid login credentials", "exclamation")
            }
        })
        .catch(error => {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(error.message, "exclamation")
        })
    }

    handleCreateInstitutionChange(e) {
        const { name, value, type, checked } = e.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleCreateInstitutionSubmit() {
        //Use the provided API to update the records after validating...
        try {
            //Else, good to go
            this.props.setIsLoaderVisible(true)

            //Set the data first...
            const data = {
                "institutionName": this.state.txtInstitutionName,
                "institutionCode": this.state.txtInstitutionCode,
                "institutionType": this.state.cbxInstitutionType,
                "billingType": this.state.cbxBillingType,
                "billingBankCode": this.state.txtBillingBankCode,
                "billingAccountNumber": this.state.txtBillingAccountNumber,
                "revenueBankCode": this.state.txtRevenueBankCode,
                "revenueAccountNumber": this.state.txtRevenueAccountNumber,
                "address": this.state.txtAddress,
                "contactEmail": this.state.txtEmail,
                "notificationUrl": this.state.txtNotifyURL,
                "allowApi": this.state.chkAllowAPIAccess,
                "canAccessEDoc": this.state.chkAllowERef,
            };
            const options = {
                method: 'POST',
                headers: this.headersData,
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/institution/create-institution', options)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast("Institution created successfully!", "success")
                            this.props.setIsCreateSuccessful(true)
                            document.querySelector(".create").classList.remove("fade")

                        } else {
                            this.props.setIsCreateSuccessful(false)
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }

                    } else {
                        this.props.ShowToast("Invalid input value(s)", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })

        } catch (err) {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(err.message, "exclamation")
        }
    }

    closeForm() {
        document?.querySelector(".create")?.classList.remove("fade")
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <div className="dialog-background create">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-building-alt"></i> Create Institution</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" />
                        <h3 className="row-title">Institution Details</h3>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label>Institution Name</label>
                                <input
                                    type="text"
                                    name="txtInstitutionName"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    value={this.state.txtInstitutionName}
                                    required />
                            </div>
                        </div>
                        <div className="form-row-3 mb-3">
                            <div className="input-group col-md">
                                <label>Institution Code</label>
                                <input
                                    type="text"
                                    name="txtInstitutionCode"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    value={this.state.txtInstitutionCode}
                                    required />
                            </div>
                            <div className="input-group col-md">
                                <label>Institution Type</label>
                                <select className="custom-select"
                                    name="cbxInstitutionType"
                                    value={this.state.cbxInstitutionType}
                                    onChange={this.handleCreateInstitutionChange}
                                >
                                    <option value=""></option>
                                    {
                                        this.state.apiData && this.state.apiData.map(item => {
                                            return (
                                                <option key={item.slug} value={item.slug}>{item.name}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>
                        </div>
                        <h3 className="row-title">Billing</h3>
                        <div className="form-row-3 mb-3">
                            <div className="input-group">
                                <label>Billing Type</label>
                                <select className="custom-select"
                                    name="cbxBillingType"
                                    value={this.state.cbxBillingType}
                                    onChange={this.handleCreateInstitutionChange}
                                >
                                    <option value=""></option>
                                    <option value="Bank">Bank</option>
                                    <option value="Non bank">Non Bank</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label>Billing Bank Code</label>
                                <input
                                    type="text"
                                    name="txtBillingBankCode"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    value={this.state.txtBillingBankCode}
                                    required />
                            </div>
                            <div className="input-group">
                                <label>Billing Account Number</label>
                                <input
                                    type="text"
                                    name="txtBillingAccountNumber"
                                    className="form-control"
                                    value={this.state.txtBillingAccountNumber}
                                    onChange={this.handleCreateInstitutionChange}
                                    maxLength="10"
                                    required />
                            </div>
                        </div>
                        <h3 className="row-title">Revenue</h3>
                        <div className="form-row-3 mb-3">
                            <div className="input-group">
                                <label>Revenue Bank Code</label>
                                <input
                                    type="text"
                                    name="txtRevenueBankCode"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    value={this.state.txtRevenueBankCode}
                                    required />
                            </div>
                            <div className="input-group">
                                <label>Revenue Account Number</label>
                                <input
                                    type="text"
                                    name="txtRevenueAccountNumber"
                                    className="form-control"
                                    value={this.state.txtRevenueAccountNumber}
                                    onChange={this.handleCreateInstitutionChange}
                                    maxLength="10"
                                    required />
                            </div>
                        </div>
                        <h3 className="row-title">Others</h3>
                        <div className="form-row mb-2">
                            <div className="input-group">
                                <label>Address</label>
                                <textarea
                                    name="txtAddress"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    value={this.state.txtAddress}
                                    rows="4"
                                    required />
                            </div>
                            <div className="input-group">
                                <label>Contact Email</label>
                                <input type="email" name="txtEmail" className="form-control" onChange={this.handleCreateInstitutionChange} required />
                                <label>Notify URL</label>
                                <input
                                    type="text"
                                    name="txtNotifyURL"
                                    className="form-control"
                                    onChange={this.handleCreateInstitutionChange}
                                    required
                                    placeholder="Company Website, etc" />
                            </div>

                            <div className="inline-input-group">
                                <label>
                                    <input type="checkbox"
                                        name="chkAllowAPIAccess"
                                        className="form-control"
                                        onChange={this.handleCreateInstitutionChange}
                                        checked={this.state.chkAllowAPIAccess}
                                    /> Allow API Access to Institution Users
                                </label>
                            </div>

                            <div className="inline-input-group">
                                <label>
                                    <input type="checkbox"
                                        name="chkAllowERef"
                                        className="form-control"
                                        onChange={this.handleCreateInstitutionChange}
                                        checked={this.state.chkAllowERef}
                                    /> Allow access to e-Reference
                                </label>
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" id="btnUpdateInstitution" name="btnUpdateInstitution" type="button" onClick={this.handleCreateInstitutionSubmit}>
                            <i className="icofont-upload-alt"></i> Create</button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit"></i> Cancel</button>
                        <button
                        id="testButton" title="TestButton"
                         className="testButton d-none" onClick={() => this.getData()} onChange={this.handleCreateInstitutionChange}>Test Button</button>
                    </div>

                </div>
            </div>
        )
    }
}

export default CreateInstitutionsForm
