/**
 * This class component
 * is the locked screed
 * which comes up after
 * 10 minutes of inactivity
 * on the site.
 * It is unlocked by the application of a valid passsword
 * Other option is to logout
 * completely from the application
 */
import React from "react";
import { Enums } from "../../../_common/enums";
/** Local import of react dom */
import ApiPath from "./api-path";
/**
 * ApiPath above represents the
 * general path of all the api's
 * THis can be changed by
 * the clients to point to the hosting service
 * URL (and/or including the port number)
 * for correct access
 */
import { LockedScreenTimeout } from "./lock-screen-timeout";
/**
 * The screenTimeout is defaulted to 10 (10 mins)
 * This file handles the output time of the lockedscreen
 * This may be modified later if the
 * client desires to change the locked screen timeout
 * Note that this is the time in minutes.
 */

class LockedScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      password: "",
      isLockedScreenVisible: false,
    };

    this.timeOut = LockedScreenTimeout * 1000 * 60;

    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleSignOut() {
    this.SignOut();
  }

  handleSubmit() {
    //Check if there's a successful login
    if (this.state.password === "") {
      this.props.ShowToast("Enter a valid password to unlock", "exclamation");
      return;
    }

    this.props.setIsLoaderVisible(true);

    const data = {
      password: this.state.password,
      isNibbsUser: sessionStorage.getItem("is_nibss_user") === "NIBSS" ? true : false,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": Enums.applicationJson,
        Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
      },
      body: JSON.stringify(data),
    };

    fetch(ApiPath + "api/auth/resume-session", options)
      ?.then((response) => {
        return response.json();
      })
      .then((response) => {
        this.props.setIsLoaderVisible(false);
        if (response) {
          if (response.ok === true) {
            //Unlock
            sessionStorage.setItem("session_locked_state", "unlocked");
            sessionStorage.setItem(
              "session_token_timestamp",
              new Date().getTime()
            );
            this.setState({
              isLockedScreenVisible: false,
              password: "",
            });
          } else {
            this.props.setIsLoaderVisible(false);
            document.getElementById("txtLockPassword").focus();
            this.props.ShowToast(
              response.message,
              "exclamation"
            );
            return;
          }
        } else {
          this.props.ShowToast("Invalid login credentials", "exclamation");
        }
      })
      .catch((error) => {
        this.props.setIsLoaderVisible(false);
        this.props.ShowToast(error.message, "exclamation");
      });
  }

  SessionStateExpired = () => {
    //Check and see if the session is set...
    const getSessionToken = sessionStorage.getItem("session_token");
    const localToken = sessionStorage.getItem("session_token");
    const tokenTimeStamp = parseInt(
      sessionStorage.getItem("session_token_timestamp")
    );

    let returnValue = false;
    const timeStamp = new Date().getTime();

    if (getSessionToken === localToken) {
      if (timeStamp - tokenTimeStamp >= this.timeOut) {
        this.setState({ isLockedScreenVisible: true });
        sessionStorage.setItem("session_locked_state", "locked");
        returnValue = true;
      }
    }

    return returnValue;
  };

  SignOut() {
    const userType = sessionStorage.getItem("is_nibss_user");

    sessionStorage.setItem("session_token_timestamp", null);
    sessionStorage.setItem("session_valid_user", null);
    sessionStorage.setItem("session_token", null);
    sessionStorage.setItem("session_locked_state", null);
    sessionStorage.setItem("session_first_name", null);
    sessionStorage.setItem("session_last_name", null);
    sessionStorage.setItem("session_user_type", null);
    sessionStorage.setItem("institution", null);
    sessionStorage.setItem("customer_branch", null);
    sessionStorage.setItem("customer_branch_slug", null);
    sessionStorage.setItem("institution_slug", null);


    //Sign out
    /**
     * This is determined by the kind of user logged in
     * NIBSS user
     * or
     */
    let loc = "";
    if (userType !== null) {
      loc = userType.indexOf("NIBSS") > -1 ? "./../nibss-user-login" : "./../";
    } else {
      loc = "./../";
    }

    window.location.href = loc;
  }

  CheckSessionState() {
    if (
      this.SessionStateExpired() &&
      sessionStorage.getItem("session_locked_state") === "unlocked"
    ) {
      //Lock screen...
      this.setState({ isLockedScreenVisible: true });
      sessionStorage.setItem("session_locked_state", "locked");
    }
  }

  CheckSessionValid() {
    if (
      sessionStorage.getItem("session_token") == null ||
      sessionStorage.getItem("session_token") === ""
    ) {
      this.SignOut();
    }
  }

  componentDidMount() {
    this.CheckSessionValid();
    if (sessionStorage.getItem("session_locked_state") === "locked") {
      this.setState({ isLockedScreenVisible: true });
    } else {
      this.setState({ isLockedScreenVisible: false });
    }

    setInterval(() => this.CheckSessionState(), 10000);

    if (
      sessionStorage.getItem("session_token") === null ||
      sessionStorage.getItem("session_token") === ""
    ) {
      this.SignOut();
      return;
    }

    if (sessionStorage.getItem("session_locked_state") === "locked") {
      document?.querySelector(".lock-screen")?.classList.add("fade");
    } else {
      document?.querySelector(".lock-screen")?.classList.remove("fade");
    }

    document.addEventListener("mousemove", () => {
      //Get time...
      const timeStamp = new Date().getTime();
      sessionStorage.setItem("session_token_timestamp", timeStamp);
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", () => {
      //Get time...
      const timeStamp = new Date().getTime();
      sessionStorage.setItem("session_token_timestamp", timeStamp);
    });
  }

  render() {
    const classValue = !this.state.isLockedScreenVisible
      ? "lock-screen"
      : "lock-screen fade";

    return (
      <div className={classValue}>
        <div className="lock-container">
          <div className="dialog-header">
            <h3>
              <i className="icofont-stopwatch"></i> Session Timed-out!
            </h3>
          </div>
          <div className="dialog-body">
            <p>Your session has timed-out. Enter your password to unlock</p>
            <div className="input-group">
              <span className="bx bx-key"></span>
              <input
                type="password"
                id="txtLockPassword"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.password}
                placeholder="Enter Password"
              />
            </div>
          </div>
          <div className="dialog-footer mt-2">
            <button
              className="btn-green t-tip mr-1"
              id="btnReLogin"
              name="btnReLogin"
              type="button"
              onClick={this.handleSubmit}
              data-title="Log back into application"
            >
              <i className="icofont-login"></i> Login
            </button>
            <button
              className="btn-grey t-tip"
              id="btnSignOut"
              name="btnSignOut"
              type="button"
              onClick={this.handleSignOut}
              data-title="Exit Application"
            >
              <i className="icofont-sign-out"></i> SignOut
            </button>
            <button id="testButton" className="d-none"
              onClick={() => {
                this.componentDidMount();
                this.componentWillUnmount();
                this.SessionStateExpired();
                this.CheckSessionState();
              }}></button>
          </div>
        </div>
      </div>
    );
  }
}
export default LockedScreen
