import SearchList from "./SearchList";

const SearchComponent = (props) => {

    return (
        <div className="search">
            <input
                type="text"
                value={props.searchValue}
                id="txtSearch"
                onChange={props.handleChange}
                className="filter-search"
                placeholder={props.searchUserPlaceholder}
            />
            <button
                id="btnSearchBy"
                className="btn-grey"
                onClick={props.toggleSearchGroup}>
                <span id="searchButtonText">
                    <b>{props.searchTitle}</b>
                </span> <i className="bx bx-chevron-down"></i>
                <span className={props.searchGroupClass} style={{ width: 270 }}>
                    {
                        props.ReferenceSearchList.map(item => {
                            return (
                                <SearchList
                                    key={item.id}
                                    id={item.idVal}
                                    value={item.value}
                                    ChangeSearchCriteria={props.ChangeSearchCriteria}
                                    searchField={props.searchField}
                                    itemName={item.itemName}
                                />
                            )
                        })
                    }
                </span>
            </button>
            <button
                id="btnSearchUser"
                className="btn-green t-tip"
                onClick={props.filterRecords}
                data-title="Filter records based on input">
                <i className="icofont-ui-search"></i>
            </button>
        </div>
    );
}

export default SearchComponent
