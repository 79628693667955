const RefReportSearchList = [

    {
        id: 1,                                  //Just for the mapping key
        idVal: "customerAccountName",           //Database searchCriteria Field
        value: "Cust Acct Name",                //Name as shown in button and placeholder
        itemName: "Customer Acct Name",         //Name as shown in list
    },
    {
        id: 2,
        idVal: "customerAccountNo",
        value: "Cust Acct No",
        itemName: "Customer Acct Number"
    },
    {
        id: 3,
        idVal: "refereeAccountName",
        value: "Ref Acct Name",
        itemName: "Referee Acct Name",
    },
    {
        id: 4,
        idVal: "refereeAccountNo",
        value: "Ref Acct No",
        itemName: "Referee Acct Number",
    },
    {
        id: 5,
        idVal: "actionStatus",
        value: "Status",
        itemName: "Status",
    },
    {
        id: 6,
        idVal: "customerInstitution",
        value: "Customer Institution",
        itemName: "Customer Institution"
    },
    {
        id: 7,
        idVal: "refereeInstitution",
        value: "Referee Institution",
        itemName: "Referee Institution",
    },
    {
        id: 8,
        idVal: "isOverDue",
        value: "Overdue Status",
        itemName: "Overdue Status",
    }
]

export default RefReportSearchList
