import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";
import SearchList from "../../../admin/assets/App/SearchList";

class ApproveDisapproveIncomingReference extends React.Component {

    constructor() {
        super()
        this.state = {
            rbtnApprove: true,
            rbtnDisapprove: false,
            txtComments: "",
            approveType: "Approve",
            searchField: "rbtnApprove"
        }
    }

    changeComments = (e) => {
        this.setState({ txtComments: e?.target.value })
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchField: e?.target.id,
        }, () => {
            this.setState({
                approveType: (this.state.searchField === "rbtnApprove") ? "Approve" : "Disapprove"
            })
        })
    }

    approveDisapproveReference = () => {

        const data = { "comment": this.state.txtComments, "slug": this.props.slug }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            body: JSON.stringify(data),
        }

        let apiPoint = "";

        if (this.state.approveType === "Approve") {
            apiPoint = "api/e-reference/approve-incoming-e-reference";
        } else {
            apiPoint = "api/e-reference/disapprove-incoming-e-reference";
        }

        const confirm = window.confirm(`Do you wish to proceed to ${this.state.approveType} the selected reference?`);
        if (confirm) {

            this.props.setIsLoaderVisible(true);

            fetch(ApiPath + apiPoint, options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast(`Reference ${this.state.approveType}d successfully!`, "success")
                            this.props.setDisplayApproveDialog(false)
                            this.props.setApproveSuccessful(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                            return;
                        }
                    } else {
                        this.props.ShowToast("Invalid input value(s)", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                    this.props.setIsLoaderVisible(false)

                })
        }

    }

    closeForm = () => {
        this.props.setDisplayApproveDialog(false)
    }

    render() {

        const dialogclass = this.props.displayApproveDialog ? "modal fade show" : "modal fade";
        return (
            <div className={dialogclass}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="create"><i className="icofont-tick-boxed"></i> Approve Reference - #{this.props.referenceNo}</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-row-3-1">
                                <label className="modal-info"><span>*</span>(Double-click to select item)</label>
                                <div className="input-group">
                                    <SearchList
                                        id="rbtnApprove"
                                        value="Approve"
                                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                                        searchField={this.state.searchField}
                                        itemName="Approve Incoming Reference"

                                    />
                                    <SearchList
                                        id="rbtnDisapprove"
                                        value="Disapprove"
                                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                                        searchField={this.state.searchField}
                                        itemName="Disapprove Incoming Reference"
                                    />
                                </div>
                                <div className="input-group" disabled={!this.state.rbtnDisapprove}>
                                    <textarea
                                        id="txtComments"
                                        name="txtComments"
                                        type="text"
                                        rows="4"
                                        className="form-control"
                                        value={this.state.txtComments}
                                        onChange={this.changeComments}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                id="btnApproveReference"
                                className="btn-green mr-1"
                                onClick={this.approveDisapproveReference}>
                                <i className="icofont-tick-mark"></i> {this.state.approveType}
                            </button>
                            <button
                                id="btnClose"
                                className="btn-grey"
                                onClick={this.closeForm}>
                                <i className="icofont-exit"></i> Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ApproveDisapproveIncomingReference;
