import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useState } from "react";

import "./admin/assets/css/calendar.css";
import "./admin/assets/css/dashboard.css";
import "./admin/assets/css/define.css";
import "./admin/assets/css/dialog.css";
import "./admin/assets/css/general.css";
import "./admin/assets/css/loading.css";
import "./admin/assets/css/lock-screen.css";
import "./admin/assets/css/main.css";
import "./admin/assets/css/toast.css";
import "./admin/assets/css/modal.css";

import "./admin/assets/200/boxicons/css/boxicons.min.css";
import "./admin/assets/200/icofont/icofont.min.css";

import { Login } from "./Login/App/Login";
import { NibssUserLogin } from "./Login/App/nibss-user-login"
import { AuditLogs } from "./admin/App/audit-logs";
import { ChangePassword } from "./users/App/change-password";
import { Dashboard } from "./admin/App/dashboard";
import { Institutions } from "./institutions/App/institutions";
import { LoginValidate } from "./Login/App/login-validate";
import { AdminEmails } from "./config/admin-group-emails/admin-group-emails";
import { InstitutionTypes } from "./config/institution-types/institution-types";
import { PublicHolidays } from "./config/public-holidays/public-holidays";
import { UserRoles } from "./config/user-roles/user-roles";
import { ConfigureVAT } from "./config/configure-vat/configure-vat";
import { ForgotPassword } from "./Login/App/forgot-password";
import { RecoverPassword } from "./Login/App/recover-password";
import { UserManagement } from "./users/App/user-management";
import { BranchManagement } from "./branch-management/App/branch-management";
import { CreateReference } from "./e-reference/App/create-reference/create-reference";
import Toast from "./admin/assets/App/toast";
import Loader from "./admin/assets/App/loader";
import { OutgoingReference } from "./e-reference/App/outgoing-references/outgoing-references";
import ErrorPage from "./ErrorPage";
import { ReferenceReports } from "./e-reference/App/reference-reports/reference-reports";
import { Billings } from "./e-reference/App/billings/billings";
import { IncomingReferences } from "./e-reference/App/incoming-references/incoming-references";
import { ScheduledBilling } from "./e-reference/App/scheduled-billing/scheduled-billing";
import { BillingFees } from "./config/billing-fees/billing-fees";

function App() {

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [toastVis, setToastVis] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [msgBoxType, setMsgBoxType] = useState("success");

  const ShowToast = (message, messageBoxType) => {
    setToastMsg(message);
    setMsgBoxType(messageBoxType);
    setToastVis(true);
  }

  return (
    <>
      <Toast
        toastMessage={toastMsg}
        msgBoxType={msgBoxType}
        toastVisibility={toastVis}
        setToastVisibility={setToastVis}
      />

      <Loader isVisible={isLoaderVisible} />

      <Router>
        <Switch>
          <Route path="/" exact>
            <Login
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />

          </Route>
          <Route path="/nibss-user-login" exact>
            <NibssUserLogin
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/auth/forgot-password" exact>
            <RecoverPassword
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/login-validate" exact>
            <LoginValidate
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>

          <Route path="/admin/dashboard" exact>
            <Dashboard
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/admin/audit-logs" exact>
            <AuditLogs
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/change-password" exact>
            <ChangePassword
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/institutions/institutions" exact>
            <Institutions
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/institutions/institutions/:id" exact>
            <Institutions
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/admin-group-emails" exact>
            <AdminEmails
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/institution-types" exact>
            <InstitutionTypes
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/user-roles" exact>
            <UserRoles
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/public-holidays" exact>
            <PublicHolidays
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/config/configure-VAT" exact>
            <ConfigureVAT
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/users/users-management" exact>
            <UserManagement
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/branch-management/branch-management" exact>
            <BranchManagement
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/create-reference" exact>
            <CreateReference
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/outgoing-references" exact>
            <OutgoingReference
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/incoming-references" exact>
            <IncomingReferences
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/reference-reports" exact>
            <ReferenceReports
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/billing" exact>
            <Billings
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/e-reference/billing-and-reports/scheduled-billings" exact>
            <ScheduledBilling
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>
          <Route path="/configuration/e-reference-and-billing/billing-fee-settings" exact>
            <BillingFees
              ShowToast={ShowToast}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Route>


          {/*404 Page */}
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>

    </>
  );
}

export default App
