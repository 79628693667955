import React from 'react'
import ImageDialog from '../create-reference/image-dialog'
import SplitDate from '../../../admin/assets/App/split-date'
import CreateFile from '../../../admin/assets/App/create-file'

export default class ViewReferenceDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            displayImage: false,
            imageSource: "",
            detailsData: [],
            referenceComments: [],
            referenceCreator: [],
            referenceLog: [],
            uploadedFile: {},
        }

        this.handleClose = this.handleClose.bind(this)

    }

    createFile = () => {
        if (this.props.data) {

            const ImageURL = this.props.data?.mandatePath
            // Create a FormData and append the file with "image" as parameter name
            this.setState({
                uploadedFile: CreateFile(ImageURL),
            })
        }
    }

    componentDidMount() {

        this.setState({
            referenceComments: this.props.data?.referenceComments,
            referenceLog: this.props.data?.referenceLog,
        })

        this.setState({
            referenceCreator: this.state.referenceComments?.createdByUser,
        })

        this.createFile();
    }

    handleClose() {
        this.props.setDisplayView(false);
    }

    displayImageDialog = () => {
        /**
         * This subroutine
         * is tasked with the sole
         * responsibility of
         * displaying the
         * actual
         * file
         * attached to
         * the form...
         */
        this?.setState({
            imageSource: this.props.data?.mandatePath ,
            displayImage: true,
        })

    }

    render() {
        const dialogClass = (this.props.displayView) ? "modal fade show" : "modal fade"

        return (
            <>
                <div className={dialogClass}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="create" style={{ fontSize: 14 }}><i className="icofont-letter"></i> View {this.props.sender} - #{this.props.data?.referenceNo}</h2>
                            </div>
                            <div className="modal-body">
                                <table className="dialog-table mb-2"><caption></caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-left">Fields</th>
                                            <th scope="col" className="align-left">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Reference NO</td>
                                            <td id="reference Number">{this.props.data?.referenceNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Number</td>
                                            <td id="customerAccountNumber">{this.props.data?.customerAccountNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Name</td>
                                            <td id="customerAccountName">{this.props.data?.customerAccountName}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Institution</td>
                                            <td id="customerAccountName">{sessionStorage.getItem("institution")}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Branch</td>
                                            <td id="customerAccountName">{this.props.data?.customerBranch?.branchName}</td>
                                        </tr>
                                        <tr>
                                            <td>Account Type</td>
                                            <td id="customerAccountType">{this.props.data?.customerAccountType}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Institution</td>
                                            <td id="refereeInstitution">{this.props.data?.receiverInstitution?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Branch</td>
                                            <td id="refereeBranch">{this.props.data?.receiverBranch?.branchName}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Number</td>
                                            <td id="refereeAccountNumber">{this.props.data?.refereeAccountNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Name</td>
                                            <td id="refereeAccountName">{this.props.data?.refereeAccountName}</td>
                                        </tr>
                                        <tr>
                                            <td>Attached Reference Form</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    id="btnAttachment"
                                                    onClick={this.displayImageDialog}
                                                    className="btn-grey">
                                                    Preview Attachment
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Comments</td>
                                            <td>{
                                                this.props.data?.referenceComments?.map(item => {
                                                    return item.body + "\r\n";
                                                })
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Presenter)</td>
                                            <td>
                                                {SplitDate(this.props.data?.createdAt).date}
                                                {" " + SplitDate(this.props.data?.createdAt).time}<br />
                                                {this.props.data?.createdByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Due Date</td>
                                            <td>{this.props.data?.dueDate}</td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Acceptance)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.acceptedByUser?.updated_at).date}
                                                {" " + SplitDate(this.props.data?.referenceLog?.acceptedByUser?.updated_at).time}<br />
                                                {this.props.data?.referenceLog?.acceptedByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Approval)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.approvedByUser?.updated_at).date}
                                                {" " + SplitDate(this.props.data?.referenceLog?.approvedByUser?.updated_at).time}<br />
                                                {this.props.data?.referenceLog?.approvedByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Rejection)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.rejectedByUser?.updated_at).date}
                                                {" " + SplitDate(this.props.data?.referenceLog?.rejectedByUser?.updated_at).time}<br />
                                                {this.props.data?.referenceLog?.rejectedByUser?.email}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <hr />
                                <button
                                    type="button"
                                    className="btn-grey mr-2"
                                    onClick={this.handleClose}
                                    id="btnCancel">
                                    <i className="icofont-exit"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
                {
                    this.state.displayImage && this.state.uploadedFile &&
                    <ImageDialog
                        isURL={false}
                        src={this.state.uploadedFile}
                        setImageDisplay={value => this.setState({ displayImage: value })}
                    />
                }

            </>
        )
    }
}
