import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import UserRoleCheckGroup from "./user-role-checkgroup"

export class CreateUserRoleDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtRoleID: "",
            txtRoleName: "",
            txtDescription: "",
            cbxEReference: false,
            cbxTrappedCards: false,
            cbxEDMMS: false,
            cbxEClips: false,
            cbxERefOperator: false,
            cbxERefAuthorizer: false,
        }

        this.handleCreateUserRoleChange = this.handleCreateUserRoleChange.bind(this)

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
        }
    }

    getRoleServices() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            roleServices: response.body.services,
                        })
                    } else {
                        return;
                    }
                }
            })
    }

    componentDidMount() {
        this?.getRoleServices()
    }

    handleCreateUserRoleChange(e) {
        const { name, value, type, checked } = e.target;
        (type === "checkbox") ? this.setState({ [name]: checked }, () => {
            if (name === "cbxEReference") {
                //Disable the other related ones if disabled
                this.ClearERefRoleServices();
            }
        }) : this.setState({ [name]: value })

    }

    ClearERefRoleServices = () => {
        if (!this.state.cbxEReference) {
            this.setState({
                cbxERefAuthorizer: false,
                cbxERefOperator: false,
            })
        }
    }

    clearForm = () => {
        //Reset all the state variables to initial values...
        this.setState({
            txtRoleID: "",
            txtRoleName: "",
            txtDescription: "",
            cbxEReference: false,
            cbxTrappedCards: false,
            cbxEDMMS: false,
            cbxEClips: false,
            cbxERefOperator: false,
            cbxERefAuthorizer: false,
        })
    }

    closeForm() {
        document.querySelector(".create-role")?.classList.remove("fade");
        this?.clearForm();
    }

    ValidateERefServiceRoles = () => {
        let proceed = false;
        const rolesArray = [];

        if (this.state.cbxEReference) {
            if (!this.state.cbxERefAuthorizer && !this.state.cbxERefOperator) {
                //Issue
                this.props.ShowToast("Select an Option for eReference to proceed.", "exclamation");

            } else {
                proceed = true;
                if (this.state.cbxERefOperator) {
                    rolesArray.push(1)
                }
                if (this.state.cbxERefAuthorizer) {
                    rolesArray.push(2)
                }
            }
        }

        //Check to see which ones match...
        return {
            ok: proceed,
            rolesArray: rolesArray,
        }

    }

    CreateUserRole = () => {
        const servicesArray = [];

        if (this.state.txtRoleName === "") {
            this.props.ShowToast("Enter a valid Role Name before proceeding.", "exclamation")
            return;
        }
        if (this.state.txtRoleID === "") {
            this.props.ShowToast("Enter a valid Role ID before proceeding.", "exclamation")
            return;
        }
        if (this.state.txtDescription === "") {
            this.props.ShowToast("Enter the Role Description.", "exclamation")
            return;
        }

        //Later we will repeat this
        // for other services
        // and get the same thing
        // off for them...
        const eRefItems = this.ValidateERefServiceRoles()
        if (!eRefItems.ok) {
            return
        } else {
            servicesArray.push({
                id: 1,
                serviceRoles: eRefItems.rolesArray,
            })
        }

        //For the updates...
        const data = {
            "name": this.state.txtRoleName,
            "roleId": this.state.txtRoleID,
            "description": this.state.txtDescription,
            "services": servicesArray,
        }

        const options = {
            method: 'POST',
            headers: this.headersData,
            body: JSON.stringify(data),
        }

        this.props.setIsLoaderVisible(true)
        fetch(ApiPath + "api/institution-user-role/create-institution-user-role", options)
            .then(response => { return response.json() })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response.ok) {
                    this.props.ShowToast("User role created successfully", "success")
                    this.clearForm();
                    this.props.setIsCreateSuccessful(true)
                    this.closeForm()
                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    render() {

        return (
            <div className="dialog-background create-role">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-user-alt-5"></i> Create User Role</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row-1-2">
                            <div className="input-group">
                                <label><span>*</span>Role ID</label>
                                <input
                                    type="text"
                                    id="txtRoleID"
                                    name="txtRoleID"
                                    className="form-control"
                                    onChange={e => this.handleCreateUserRoleChange(e)}
                                    value={this.state.txtRoleID} />
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Role Name</label>
                                <input
                                    type="text"
                                    id="txtRoleName"
                                    name="txtRoleName"
                                    className="form-control"
                                    onChange={this.handleCreateUserRoleChange}
                                    value={this.state.txtRoleName} />
                            </div>
                        </div>
                        <div className="form-row-3x mb-2">
                            <div className="input-group">
                                <label><span>*</span>Description</label>
                                <textarea
                                    name="txtDescription"
                                    id="txtDescription"
                                    className="form-control"
                                    onChange={e => this.handleCreateUserRoleChange(e)}
                                    value={this.state.txtDescription}
                                    maxLength="499"
                                    rows="4"
                                >
                                </textarea>
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Services & Role Services</h3>
                        <UserRoleCheckGroup
                            cbxEReference={this.state.cbxEReference}
                            cbxTrappedCards={this.state.cbxTrappedCards}
                            cbxEDMMS={this.state.cbxEDMMS}
                            cbxEClips={this.state.cbxEClips}
                            handleChange={this.handleCreateUserRoleChange}
                        />
                    </div>
                    <div className="dialog-footer">
                        <button type="button" id="btnCreate"
                            className="btn-green mr-1" name="btnCreate"
                            onClick={this.CreateUserRole}><i className="icofont-upload-alt"></i> Create User Role</button>
                        <button type="button" className="btn-grey mr-1" id="btnCancel"
                            onClick={this.closeForm}><i className="icofont-exit"></i> Close</button>
                        <button id="testButton" className="d-none"
                            onClick={() => {
                                this.componentDidMount();
                            }} onChange={this.handleCreateUserRoleChange}>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateUserRoleDialog
